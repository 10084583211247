import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getUserData,
  getIdentityStatus,
  getBlacklistStatus,
  getUserLimit,
  getUserAttr,
  updateUserAttr
} from '../../../actions/users';
import DetailsHeader from '../DetailsHeader';
import PersonalInfo from '../PersonalInfo';
import ComplianceDesk from '../ComplianceDesk';
import Comments from '../Comments';
import UserDetailsBottom from '../UserDetailsBottom';
import UserStatus from '../UserStatus';
import Fee from '../Fee';
import UserAttr from '../UserAttr';
import Dropdown from '../../../components/Dropdown';
import { rolesObj } from '../../../utils/roles.const';
import { calculateAttributes } from '../../../utils/wallet.utils';
import './UserDetails.scss';

const UserDetails = ({ userAddress, users, roles }) => {
  const [user, setUser] = useState(null);
  const [userStatus, setUserStatus] = useState(false);
  const [userLimit, setUserLimit] = useState('');
  const [attr, setAttr] = useState([]);
  const [blacklistStatus, setBlacklistStatus] = useState(false);
  const [error, setError] = useState('');
  const isComplianceSu = useMemo(() => {
    return roles.indexOf(rolesObj.complianceSu) !== -1;
  }, [roles]);

  const getDetails = async userAddress => {
    setError('');
    try {
      const response = await Promise.all([
        getUserData(userAddress),
        getIdentityStatus(userAddress),
        getBlacklistStatus(userAddress),
        getUserLimit(userAddress),
        getUserAttr(userAddress)
      ]);

      setUser(response[0]);
      setUserStatus(response[1].result);
      setBlacklistStatus(response[2].result);
      setUserLimit(response[3]);
      setAttr(calculateAttributes(response[4]));
    } catch (error) {
      setError(error);
    }
  };

  const updateAttr = async (name, active, updatedAttr) => {
    try {
      setAttr(updatedAttr);
      await updateUserAttr(userAddress, { name, active });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (!userAddress) return;
    getDetails(userAddress);
  }, [userAddress, users]);

  if (!userAddress) return null;

  if (error) {
    return (
      <div
        data-testid="user-details"
        className="error-text compliance__user-details__error"
      >
        {error}
      </div>
    );
  }

  return (
    <Fragment>
      {user && (
        <div data-testid="user-details" className="compliance__user-details">
          <DetailsHeader user={user} />
          <div className="compliance-status">
            <UserStatus
              user={user}
              status={userStatus}
              getDetails={getDetails}
              blacklistStatus={blacklistStatus}
              userLimit={userLimit}
            />
            <UserAttr
              attr={attr}
              username={userAddress}
              updateAttr={updateAttr}
            />
            <Fee
              exchangeFee={user.exchangeFee}
              username={userAddress}
              getDetails={getDetails}
            />
          </div>
          <Dropdown title="User Data">
            <PersonalInfo user={user} getDetails={getDetails} />
          </Dropdown>
          <Dropdown title="Compliance Desk">
            <ComplianceDesk user={user} getDetails={getDetails} />
          </Dropdown>

          <Dropdown title="Comments">
            <Comments
              comments={user.comments}
              username={user.username}
              getDetails={getDetails}
            />
          </Dropdown>
          {(user.currentProcessStatus !== 'CLOSED' ||
            (user.currentProcessStatus === 'CLOSED' &&
              userStatus &&
              isComplianceSu &&
              !user.confirmedByComplianceSu)) && (
              <UserDetailsBottom
                user={user}
                getDetails={getDetails}
                status={userStatus}
                blacklistStatus={blacklistStatus}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

UserDetails.propTypes = {
  userAddress: PropTypes.string,
  users: PropTypes.object,
  roles: PropTypes.array
};

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles
});

export default connect(
  mapStateToProps,
  null
)(UserDetails);
