import React from 'react';
import PropTypes from 'prop-types';

import './InfoMessage.scss';

const InfoMessage = ({ name, timestamp, message }) => {
  return (
    <div className="compliance__info-message">
      <div>
        {name} {timestamp}
      </div>
      <div>{message}</div>
    </div>
  );
};

InfoMessage.propTypes = {
  name: PropTypes.string,
  timestamp: PropTypes.string,
  message: PropTypes.string
};

export default InfoMessage;
