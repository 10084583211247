import React from 'react';
import PropTypes from 'prop-types';
import AttrItem from './AttrItem';
import './UserAttr.scss';

const UserAttr = ({ attr, updateAttr, username }) => {
  const onChange = async (name, active) => {
    let updatedAttr = [...attr];
    updatedAttr = updatedAttr.map(a =>
      a.name === name ? { name, active } : a
    );
    try {
      await updateAttr(name, active, updatedAttr);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  if (!attr) {
    return (
      <div className="comliance-user-attr">
        <span>Wallet Attributes</span>
        <span>User needs to add a wallet for Wallet attributes to show</span>
      </div>
    );
  }

  return (
    <div className="comliance-user-attr">
      <span>Wallet Attributes</span>
      <div className="comliance-user-attr__wrapper">
        {attr &&
          attr.map(a => (
            <AttrItem
              attr={a}
              key={a.name}
              onChange={onChange}
              username={username}
            />
          ))}
      </div>
    </div>
  );
};

UserAttr.propTypes = {
  attr: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  username: PropTypes.string,
  updateAttr: PropTypes.func
};

export default UserAttr;
