import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Loader from '../../../components/Loader';
import { getOrders } from '../../../actions/userOrders';
import NoUserOrders from '../NoUserOrders';
import Controls from '../Controls';
import warningIcon from '../../../assets/warning-icon.svg';
import ethereumIcon from '../../../assets/ethereum-icon.svg';
import logo from '../../../assets/element36-logo.svg';

import Date from '../Date';
import User from '../User';
import Account from '../Account';
import Amount from '../Amount';
import Status from '../Status';
import Payment from '../Payment';
import MintEvent from '../MintEvent';
import Actions from '../Actions';

import './OrdersTable.scss';

const OrdersTable = ({ isSell, hideMint }) => {
  const _isMounted = useRef(true);
  const [orders, setOrders] = useState(null);
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);

  const getUserOrders = async filters => {
    setFetching(true);
    try {
      const response = await getOrders(isSell, filters);
      if (_isMounted.current) {
        setOrders(response);
        setError(null);
      }
    } catch (error) {
      setError(error.message);
    }
    setFetching(false);
  };

  const errorCallback = useCallback(
    error => setError(error ? error.message : null),
    []
  );

  const actionCallback = useCallback((id, newStatus) => {
    if (!orders) return;
    const updatedOrders = orders.map(order => {
      if (order.id === id) {
        order.status = newStatus;
        if (!isSell && newStatus === 'OPEN') order.alertMessage = null;
        if (isSell && newStatus === 'RELEASED') order.alertMessage = null;
      }
      return order;
    });
    setOrders(updatedOrders);
  }, [orders]);

  useEffect(() => {
    getUserOrders();
  }, []);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  if (!orders && fetching) return <Loader />;

  return (
    <>
      <Controls getOrders={getUserOrders} />
      {error && <p className="user-orders__table__error">{error}</p>}
      {orders && orders.length === 0 && <NoUserOrders />}
      {orders && orders.length > 0 && (
        <div className="user-orders__table">
          <div className="user-orders__table-head user-orders__table-row">
            <div className="user-orders__table__field --date">Date</div>
            <div
              className={`user-orders__table__field --user ${
                isSell ? '--sell' : ''
              }`}
            >
              User
            </div>
            {isSell && !hideMint && (
              <div className="user-orders__table__field --mint --center">
                Burn Event
              </div>
            )}
            <div className="user-orders__table__field --amount">Amount</div>
            {isSell && (
              <div className="user-orders__table__field --source">Source</div>
            )}
            <div className="user-orders__table__field --status">
              Order Status
            </div>
            <div className="user-orders__table__field --payment">
              Payment {isSell ? 'triggered' : 'received'}
            </div>
            {!isSell && !hideMint && (
              <div className="user-orders__table__field --mint --center">
                Mint Event
              </div>
            )}
            <div className="user-orders__table__field --alert">Alert</div>
            <div className="user-orders__table__field --actions" />
          </div>

          <div className="user-orders__table-body">
            {orders.map(order => (
              <div className="user-orders__table-row" key={order.id}>
                <div className="user-orders__table__field --date">
                  <Date date={order.date} />
                </div>
                <div
                  className={`user-orders__table__field --user ${
                    isSell ? '--sell' : ''
                  }`}
                >
                  <Account
                    account={isSell ? order.sourceAddress : order.targetAddress}
                  />
                  <User name={order.user} />
                </div>
                {isSell && !hideMint && (
                  <div className="user-orders__table__field --mint --center">
                    {order.txHash && <MintEvent hash={order.txHash} />}
                  </div>
                )}
                <div className="user-orders__table__field --amount">
                  <Amount amount={order.amount} symbol={order.symbol} />
                </div>
                {isSell && (
                  <div className="user-orders__table__field --source">
                    {order.sellOrder ? (
                      <img src={logo} alt="" />
                    ) : (
                      <img src={ethereumIcon} alt="" />
                    )}
                  </div>
                )}
                <div className="user-orders__table__field --status">
                  <Status status={order.status} />
                </div>
                <div className="user-orders__table__field --payment">
                  {(order.paymentTriggered || order.paymentReceived) && (
                    <Payment
                      amount={
                        order.paymentTriggered
                          ? order.amount - order.fee
                          : order.amount
                      }
                      symbol={order.symbol}
                      date={order.paymentDate}
                    />
                  )}
                </div>
                {!isSell && !hideMint && (
                  <div className="user-orders__table__field --mint --center">
                    {order.txHash && <MintEvent hash={order.txHash} />}
                  </div>
                )}
                <div className="user-orders__table__field --alert">
                  {order.alertMessage && (
                    <Tooltip title={order.alertMessage} placement="top">
                      <img src={warningIcon} alt="Alert" />
                    </Tooltip>
                  )}
                </div>
                <div className="user-orders__table__field --actions">
                  <Actions
                    id={order.id}
                    status={order.status}
                    isSell={isSell}
                    errorCallback={errorCallback}
                    actionCallback={actionCallback}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

OrdersTable.propTypes = {
  isSell: PropTypes.bool,
  hideMint: PropTypes.bool
};

export default OrdersTable;
