import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withWeb3 } from '../../../components/Web3Provider';
import { Grid, Typography } from '@material-ui/core';

import addCash36 from '../../../Cash36Provider';
import { getComplianceBalance } from '../../../actions/tokens';

const ComplianceBalance = ({
  web3,
  getComplianceBalance,
  tokenAddress,
  cash36ComplianceContract,
  officerAddress,
  balance
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    getComplianceBalance(tokenAddress, getComplianceBalanceFromBlockchain);
  }, []);

  const getComplianceBalanceFromBlockchain = async () => {
    try {
      const officerAddress = await cash36ComplianceContract.methods
        .complianceOfficer()
        .call();
      let balance = await web3.eth.getBalance(officerAddress);
      balance = await web3.utils.fromWei(balance, 'ether');

      return { officerAddress, balance };
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography color="textSecondary" style={{ fontSize: '1.6rem' }}>
          Compliance Officer
        </Typography>
        <Typography noWrap style={{ fontSize: '1.6rem' }}>
          {officerAddress}
        </Typography>
        <Typography color="textSecondary">
          {`Balance: ${balance.substring(0, 4)} ETH`}
        </Typography>
        {error && <p className="error-text">{error}</p>}
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = ({ tokens: { tokens } }, { tokenAddress }) => {
  const token = tokens.filter(token => token.address === tokenAddress)[0];
  if (token.complianceBalance) {
    return {
      balance: token.complianceBalance.balance,
      officerAddress: token.complianceBalance.officerAddress
    };
  }
  return {
    balance: '',
    officerAddress: ''
  };
};

ComplianceBalance.propTypes = {
  web3: PropTypes.object,
  getComplianceBalance: PropTypes.func,
  cash36ComplianceContract: PropTypes.object,
  balance: PropTypes.string,
  officerAddress: PropTypes.string
};

export default addCash36(
  withWeb3(
    connect(
      mapStateToProps,
      { getComplianceBalance }
    )(ComplianceBalance)
  )
);
