import React from 'react';
import PropTypes from 'prop-types';
import UserIcon from '@material-ui/icons/PermIdentity';

import { truncateString } from '../../../utils/strings.utils';

import './UsersListBlock.scss';

const UsersListBlock = ({
  users,
  icon = '',
  letter = '',
  onUserClick,
  selectedUser
}) => {
  if (users.length === 0) return null;

  const renderAvatar = (avatarUrl, name, firstName, lastName) => {
    if (avatarUrl && firstName && lastName) {
      return (
        <div className="compliance__users-list__avatar">
          <img src={avatarUrl} alt={`${name} avatar`} />
        </div>
      );
    }

    if (!avatarUrl && firstName && lastName) {
      const initials = `${firstName.split('')[0]}${lastName.split('')[0]}`;

      return (
        <div className="compliance__users-list__avatar">
          <span className="compliance__users-list__avatar--initials">
            {initials}
          </span>
        </div>
      );
    }

    return (
      <div className="compliance__users-list__avatar">
        <span className="compliance__users-list__avatar--icon">
          <UserIcon />
        </span>
      </div>
    );
  };

  return (
    <div className="compliance__users-list-block">
      <div className="compliance__users-list-block__icon">
        {icon ? <img src={icon} alt="" /> : <span>{letter}</span>}
      </div>
      <div className="compliance__users-list-block__list">
        {users.map(user => {
          const { username, firstName, lastName, avatarUrl } = user;

          const isSelected = selectedUser === username;

          const name = firstName
            ? `${firstName} ${lastName}`
            : truncateString(username, 12);
          return (
            <div
              key={username}
              data-testid="compliance__users-list__user"
              className="compliance__users-list__user"
              onClick={() => onUserClick(username)}
            >
              {renderAvatar(avatarUrl, name, firstName, lastName)}
              <div
                className={`compliance__users-list__name ${isSelected &&
                  'compliance__users-list__name__selected'}`}
              >
                {name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

UsersListBlock.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  letter: PropTypes.string,
  onUserClick: PropTypes.func,
  icon: PropTypes.string,
  selectedUser: PropTypes.string
};

export default UsersListBlock;
