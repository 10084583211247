import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Replay';

import './RefreshButton.scss';

const RefreshButton = ({ onClick }) => {
  const [refreshing, setRefreshing] = useState(false);
  const _isMounted = useRef(true);

  const refreshUsersList = async () => {
    setRefreshing(true);
    try {
      await onClick();
      setTimeout(() => {
        if (_isMounted.current) setRefreshing(false);
      }, 1000);
    } catch (e) {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <button
      data-testid="refresh-button"
      className={`paper refresh-button ${
        refreshing ? 'refresh-button--active' : null
      }`}
      onClick={refreshUsersList}
    >
      <RefreshIcon />
    </button>
  );
};

RefreshButton.propTypes = {
  onClick: PropTypes.func
};

export default RefreshButton;
