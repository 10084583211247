import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Restore } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import BlacklistUserDialog from '../BlacklistUserDialog';
import History from '../History';
import CheckVideos from '../CheckVideos';
import metamaskLogo from '../../../assets/metamask.svg';
import uportLogo from '../../../assets/uport.svg';
import { rolesObj } from '../../../utils/roles.const';

import { BigInteger } from 'js-big-integer';

import './UserStatus.scss';

let maxLimit = 115792089237316195423570985008687907853269984665640564039457584007913129639935;

const UserStatus = ({
  user: { accounts, username, currentProcessStatus, checklist, riskEvaluation },
  blacklistStatus,
  getDetails,
  roles,
  status,
  userLimit
}) => {
  const [activeDialog, setActiveDialog] = useState(false);
  const showBlacklistBtn =
    roles.indexOf(rolesObj.compliance) !== -1 ||
    roles.indexOf(rolesObj.complianceSu) !== -1;
  const closeDialog = () => setActiveDialog(false);
  const openDialog = () => setActiveDialog(true);

  const isMaxLimit = useMemo(() => {
    return BigInteger.equal(maxLimit, userLimit);
  }, [userLimit]);

  const renderWalletIcon = walletType => {
    if (walletType === 'METAMASK') return metamaskLogo;
    if (walletType === 'UPORT') return uportLogo;
  };

  return (
    <>
      <Dialog open={activeDialog} onClose={closeDialog} maxWidth={false}>
        <History
          username={username}
          close={closeDialog}
          checklist={checklist}
          riskEvaluation={riskEvaluation}
        />
      </Dialog>

      <div>
        <div className="compliance-status__current-process">
          Process Status:{' '}
          {currentProcessStatus === 'CLOSED' && status
            ? 'Verified'
            : currentProcessStatus
                .split('_')
                .join(' ')
                .toLowerCase()}
        </div>
        <div className="compliance-status__icons">
          <CheckVideos userId={username} />
          <div className="compliance-status__restore" onClick={openDialog}>
            <Restore />
          </div>
        </div>
      </div>
      <div className="compliance-status__info">
        <div className="compliance-status__address">
          <div>
            <span>Ethereum Account Address</span>
            <span>Type</span>
          </div>
          {accounts.map(({ accountAddress, walletType }) => {
            return (
              <div key={accountAddress}>
                {accountAddress}
                <img
                  className="compliance-personal-info__image"
                  src={renderWalletIcon(walletType)}
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <div>
          <span>Status</span>
          <div
            className={`compliance-status__indicator ${
              !status ? '--inactive' : '--active'
            }`}
          />
        </div>
        <div>
          <span>Limit</span>
          <div className="compliance__status__limit">
            {isMaxLimit ? <span>&infin;</span> : userLimit}
          </div>
        </div>
        {showBlacklistBtn && (
          <BlacklistUserDialog
            username={username}
            getDetails={getDetails}
            blacklistStatus={blacklistStatus}
          />
        )}
      </div>
    </>
  );
};

UserStatus.propTypes = {
  user: PropTypes.object.isRequired,
  blacklistStatus: PropTypes.bool,
  getDetails: PropTypes.func,
  status: PropTypes.bool,
  roles: PropTypes.array,
  userLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles
});

export default connect(mapStateToProps, null)(UserStatus);
