import React from 'react';
import PropTypes from 'prop-types';
import './Account.scss';

const Account = ({ account }) => (
  <div className="user-orders__account">{account}</div>
);

Account.propTypes = {
  account: PropTypes.string
};

export default Account;
