import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';

import {
  confirmUserIdentity,
  denyUserIdentity,
  confirmUserByMgmt,
  confirmUserByComplianceSU
} from '../../../actions/users';
import ConfirmBox from './ConfirmBox';
import CheckList from './CheckList';
import DialogHeader from './DialogHeader';
import ConfirmProgress from './ConfirmProgress';
import ActiveActions from './ActiveActions';
import { rolesObj } from '../../../utils/roles.const';

import './ActionButtons.scss';

const ActionButtons = ({
  username,
  currentProcessStatus,
  getDetails,
  checklist,
  riskLevel,
  roles,
  confirmedByMgmt,
  confirmedByComplianceSu,
  status
}) => {
  const [confirmUserDialogOpen, setConfirmUserDialogOpen] = useState(false);
  const [deny, setDeny] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [error, setError] = useState('');
  const userRoles = useMemo(() => {
    return {
      management: roles.includes(rolesObj.management),
      complianceSu: roles.includes(rolesObj.complianceSu),
      compliance: roles.includes(rolesObj.compliance)
    };
  }, [roles]);

  const handleConfirmClick = () => {
    setDeny(false);
    setConfirmUserDialogOpen(true);
  };

  const handleDenyClick = () => {
    setDeny(true);
    setConfirmUserDialogOpen(true);
  };

  const handleDialogClose = () => {
    setError('');
    setConfirmUserDialogOpen(false);
  };

  const identityAction = async () => {
    setConfirming(true);
    setError('');
    try {
      if (deny) {
        await denyUserIdentity(username);
      } else {
        if (userRoles.management && !confirmedByMgmt) {
          await confirmUserByMgmt(username);
        }

        if (
          currentProcessStatus === 'CLOSED' &&
          status &&
          userRoles.complianceSu &&
          !confirmedByComplianceSu
        ) {
          await confirmUserByComplianceSU(username);
        }

        if (
          currentProcessStatus === 'AWAITING_VERIFICATION' &&
          (userRoles.compliance || userRoles.complianceSu)
        ) {
          await confirmUserIdentity(username);
        }
      }
      handleDialogClose();
      setConfirming(false);
      getDetails(username);
    } catch (error) {
      setConfirming(false);
      setError(error);
    }
  };

  const checklistItemsLeft = Object.keys(checklist).filter(
    el => checklist[el] === false
  );

  const showChecklistOnConfirm = () => {
    if (
      checklistItemsLeft.length === 1 &&
      checklistItemsLeft[0] === 'confirmationOfGL'
    ) {
      return false;
    }

    if (checklistItemsLeft.length === 0) return false;

    return true;
  };

  return (
    <div className="compliance__action-buttons">
      <ActiveActions
        currentProcessStatus={currentProcessStatus}
        handleDenyClick={handleDenyClick}
        handleConfirmClick={handleConfirmClick}
        roles={userRoles}
        status={status}
        confirmedByComplianceSu={confirmedByComplianceSu}
        confirmedByMgmt={confirmedByMgmt}
      />

      <Dialog
        open={confirmUserDialogOpen}
        disableBackdropClick={confirming}
        disableEscapeKeyDown={confirming}
        onClose={() => {
          setConfirmUserDialogOpen(false);
        }}
      >
        <div className="paper compliance__action-buttons__confirm-box">
          {confirming ? (
            <ConfirmProgress deny={deny} />
          ) : (
            <>
              <DialogHeader handleDialogClose={handleDialogClose} />
              {!deny && showChecklistOnConfirm() && (
                <CheckList list={checklist} riskLevel={riskLevel} />
              )}
              <ConfirmBox
                error={error}
                identityAction={identityAction}
                handleDialogClose={handleDialogClose}
                deny={deny}
              />
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

ActionButtons.propTypes = {
  currentProcessStatus: PropTypes.string,
  getDetails: PropTypes.func,
  username: PropTypes.string,
  status: PropTypes.bool,
  confirmedByMgmt: PropTypes.bool,
  confirmedByComplianceSu: PropTypes.bool,
  addToBlacklist: PropTypes.func,
  removeFromBlacklist: PropTypes.func,
  checklist: PropTypes.object,
  riskLevel: PropTypes.string,
  rols: PropTypes.array
};

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles
});

export default connect(
  mapStateToProps,
  null
)(ActionButtons);
