import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './Date.scss';

const Date = ({ date }) => (
  <div className="user-orders__date">
    <span>{moment(date, 'DD-MM-YYYY').format('MMM')}</span>
    <span>{moment(date, 'DD-MM-YYYY').format('DD')}</span>
  </div>
);

Date.propTypes = {
  date: PropTypes.string
};

export default Date;
