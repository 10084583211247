import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import addCash36 from '../../../Cash36Provider';

import CHF36Icon from '../../../assets/CHF36-icon.svg';
import EUR36Icon from '../../../assets/EUR36-icon.svg';
import USD36Icon from '../../../assets/USD36-icon.svg';
import GBP36Icon from '../../../assets/GBP36-icon.svg';

import './TokenDetails.scss';

const TokenDetails = ({
  symbol,
  name,
  address,
  totalSupply,
  decimals,
  web3
}) => {
  const getIcon = () => {
    switch (symbol) {
      case 'EUR36':
        return EUR36Icon;
      case 'CHF36':
        return CHF36Icon;
      case 'USD36':
        return USD36Icon;
      case 'GBP36':
        return GBP36Icon;
      default:
        return null;
    }
  };

  const total = useMemo(() => {
    const totalTokenSupply = web3.utils.fromWei(totalSupply);
    return Number(totalTokenSupply).toFixed(2);
  }, [totalSupply]);

  return (
    <div className="token-details">
      <img className="token-details__image" src={getIcon()} alt="" />
      <div className="token-details__symbol">{symbol}</div>
      <div className="token-details__name">{name}</div>
      <div className="token-details__address">{address}</div>
      <div className="token-details__total-supply">
        {total} {symbol}
      </div>
    </div>
  );
};

TokenDetails.propTypes = {
  symbol: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  totalSupply: PropTypes.string
};

export default addCash36(TokenDetails);
