export default {
  root: {
    margin: 0
  },
  exchange: {
    '&:not(:first-child)': {
      marginTop: '.5rem'
    }
  },
  removeButton: {
    padding: 0
  },
  balance: {
    fontSize: '1.4rem'
  }
};
