import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const TransactionPending = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress size={100} color="primary" />
  </div>
);

TransactionPending.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(TransactionPending);
