import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { addToBlacklist, removeFromBlacklist } from '../../../actions/users';

import TextInput from '../../../components/TextInput';
import StyledButton from '../../../components/StyledButton';

import './BlacklistUserDialog.scss';

const BlacklistUserDialog = ({ username, getDetails, blacklistStatus }) => {
  const [blacklistDialogOpen, setBlacklistDialogOpen] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [confirming, setConfirming] = useState(false);
  const [error, setError] = useState('');

  const handleDialogClose = () => {
    if (!confirming) {
      setError('');
      setBlacklistDialogOpen(false);
    }
  };

  const blockUser = async () => {
    setConfirming(true);
    try {
      await addToBlacklist(username, remarks);
      setBlacklistDialogOpen(false);
      setConfirming(false);
      getDetails(username);
      setRemarks('');
    } catch (error) {
      setConfirming(false);
      setError(error);
    }
  };

  const unblockUser = async () => {
    setConfirming(true);
    try {
      await removeFromBlacklist(username, remarks);
      setBlacklistDialogOpen(false);
      setConfirming(false);
      getDetails(username);
      setRemarks('');
    } catch (error) {
      setConfirming(false);
      setError(error);
    }
  };

  const handleUserBlacklistSubmit = event => {
    event.preventDefault();
    setError('');

    if (!remarks) {
      setError('You must enter a remark first');
      return;
    }

    if (blacklistStatus) {
      unblockUser();
    } else {
      blockUser();
    }
  };

  return (
    <div className="blacklist-user-wrapper">
      <Dialog
        open={blacklistDialogOpen}
        onClose={handleDialogClose}
        disableBackdropClick={confirming}
        disableEscapeKeyDown={confirming}
        className={`blacklist-user-dialog ${confirming &&
          'blacklist-user-dialog__loading'}`}
      >
        {confirming ? (
          <div>
            <CircularProgress />
            <div>
              {blacklistStatus ? 'Unblocking user' : 'Blocking user'} - Please
              wait, this will take a moment...
            </div>
          </div>
        ) : (
          <div>
            <button
              className="blacklist-user-dialog__close"
              onClick={handleDialogClose}
            >
              <CloseIcon />
            </button>
            <div>In order to complete the action, write some text here</div>
            <form
              onSubmit={event => {
                handleUserBlacklistSubmit(event, blacklistStatus);
              }}
            >
              <TextInput
                value={remarks}
                onChange={event => {
                  setRemarks(event.target.value);
                }}
                label=""
                name=""
              />
              <StyledButton
                fullWidth
                type="submit"
                className={
                  blacklistStatus
                    ? 'blacklist-user-dialog__unblock'
                    : 'blacklist-user-dialog__block'
                }
              >
                {blacklistStatus ? 'Unblock' : 'Block'}
              </StyledButton>
              <div className="error-text">{error}</div>
            </form>
          </div>
        )}
      </Dialog>
      <StyledButton
        onClick={() => {
          setBlacklistDialogOpen(true);
        }}
        disabled={confirming}
        className={`blacklist-user-dialog__button ${
          blacklistStatus
            ? 'blacklist-user-dialog__unblock'
            : 'blacklist-user-dialog__block'
        }`}
      >
        {blacklistStatus ? 'Unblock' : 'Block'}
      </StyledButton>
    </div>
  );
};

BlacklistUserDialog.propTypes = {
  username: PropTypes.string,
  getDetails: PropTypes.func,
  status: PropTypes.bool
};

export default BlacklistUserDialog;
