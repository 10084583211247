import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

import UserDocuments from '../UserDocuments';
import StatusIcon from '../../../components/StatusIcon';

import './PersonalInfo.scss';
import parseIncomeValue from '../../../utils/parseValues.utils';

const PersonalInfo = ({
  user: {
    firstName,
    lastName,
    dateOfBirth,
    email,
    street,
    streetNr,
    city,
    zip,
    country,
    nationality,
    nationality2,
    nationality3,
    nationality4,
    bankAccounts,
    checklist,
    industry,
    profession,
    sourceOfFunds,
    sourceOfFundsOther,
    sourceOfFundsDescription,
    transactionVolume,
    income,
    industryOther
  },
  user
}) => {
  return (
    <div className="compliance-personal-info">
      <div className="compliance-personal-info__section">
        <div className="compliance-personal-info__item">
          <span>First Name</span>
          {firstName}
        </div>
        <div className="compliance-personal-info__item">
          <span>Last Name</span>
          {lastName}
        </div>
        <div className="compliance-personal-info__item">
          <span>Date of Birth</span>
          {dateOfBirth}
        </div>
        <div className="compliance-personal-info__item">
          <span>Email Address</span>
          {email}
        </div>
        <div className="compliance-personal-info__item">
          <span>Street</span>
          {street}
        </div>
        <div className="compliance-personal-info__item">
          <span>Street Number</span>
          {streetNr}
        </div>
        <div className="compliance-personal-info__item">
          <span>Town/City</span>
          {city}
        </div>
        <div className="compliance-personal-info__item">
          <span>ZIP Code</span>
          {zip}
        </div>
        <div className="compliance-personal-info__item --alt">
          <span>Country of residence</span>
          <span>
            {country.code && <ReactCountryFlag code={country.code} svg />}
            {country.name}
          </span>
        </div>
        <div className="compliance-personal-info__item">
          <span>Nationality 1</span>
          <span>
            {nationality.code && (
              <ReactCountryFlag code={nationality.code} svg />
            )}
            {nationality.name}
          </span>
        </div>
        {nationality2 && (
          <div className="compliance-personal-info__item">
            <span>Nationality 2</span>
            <span>
              <ReactCountryFlag code={nationality2.code} svg />{' '}
              {nationality2.name}
            </span>
          </div>
        )}
        {nationality3 && (
          <div className="compliance-personal-info__item">
            <span>Nationality 3</span>
            <span>
              <ReactCountryFlag code={nationality3.code} svg />{' '}
              {nationality3.name}
            </span>
          </div>
        )}
        {nationality4 && (
          <div className="compliance-personal-info__item">
            <span>Nationality 4</span>
            <span>
              <ReactCountryFlag code={nationality4.code} svg />{' '}
              {nationality4.name}
            </span>
          </div>
        )}
      </div>
      <div className="compliance-personal-info__section --bank-section">
        <h3>Bank Account</h3>
        <div className="compliance-personal-info__item">
          <span>IBAN</span>
          {bankAccounts.length && bankAccounts[0].iban}
        </div>
        <div className="compliance-personal-info__item --bank-item">
          {checklist.bankTransferReceived ? (
            <StatusIcon valid />
          ) : (
            <StatusIcon />
          )}{' '}
          Bank transfer from User received
        </div>
      </div>
      <div className="compliance-personal-info__section">
        <h3>User Profile</h3>
        <div className="compliance-personal-info__item">
          <span>Profession</span>
          {profession || 'N/A'}
        </div>
        <div className="compliance-personal-info__item">
          <span>Yearly Income</span>
          {parseIncomeValue(income)}
        </div>
        <div className="compliance-personal-info__item">
          <span>Industry</span>
          {industry || 'N/A'}
        </div>
        <div className="compliance-personal-info__item">
          {industryOther && (
            <Fragment>
              <span>Other</span>
              {industryOther}
            </Fragment>
          )}
        </div>
        <div className="compliance-personal-info__item">
          <span>Source of Funds</span>
          {sourceOfFunds.map((source, index) => {
            return (
              <Fragment key={index}>
                {source === 'SavingsFromWork' ? 'Savings from Work' : source}
                {index + 1 !== sourceOfFunds.length && ', '}
              </Fragment>
            );
          })}
        </div>
        <div className="compliance-personal-info__item">
          {sourceOfFundsOther && (
            <Fragment>
              <span>Other</span>
              {sourceOfFundsOther}
            </Fragment>
          )}
        </div>
        <div className="compliance-personal-info__item">
          <span>Transaction Volume</span>
          {parseIncomeValue(transactionVolume)}
        </div>
        {sourceOfFundsDescription && (
          <div className="compliance-personal-info__item">
            <span>Detailed description of Source of Funds </span>
            {sourceOfFundsDescription}
          </div>
        )}
      </div>
      <div className="compliance-personal-info__section --documents-section">
        <h3>Documents</h3>
        <UserDocuments user={user} />
      </div>
    </div>
  );
};
PersonalInfo.propTypes = {
  user: PropTypes.object.isRequired
};

export default PersonalInfo;
