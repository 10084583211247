export default theme => ({
  root: {
    // position: 'relative'
  },
  icon: {
    fontSize: '2.5rem',
    color: theme.palette.greys.darkGrey,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  menu: {
    position: 'absolute',
    top: '5%',
    left: '-2%'
  },
  li: {
    padding: '0'
  },
  button: {
    color: theme.palette.common.black,
    fontSize: '1.6rem',
    fontWeight: '500',
    outline: 'none',
    border: 'none',
    height: '100%',
    width: '100%',
    padding: '1.2rem',
    cursor: 'pointer'
  }
});
