import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/KeyboardArrowDown';

import { logout,mint,incoming,outgoing } from '../../../actions/auth';

import styles from './styles';

const HeaderMenu = ({ classes, logout,mint, incoming,outgoing}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <MenuIcon onClick={handleMenuClick} className={classes.icon} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem className={classes.li}>
          <button
            onClick={() => {
              logout();
            }}
            className={classes.button}
          >
            Logout
          </button>
        </MenuItem>

        <MenuItem className={classes.li}>
          <button
            onClick={() => {
              mint();
            }}
            className={classes.button}
          >
            Start Mint Process 
          </button>
        </MenuItem>

        <MenuItem className={classes.li}>
          <button
            onClick={() => {
              incoming();
            }}
            className={classes.button}
          >
            Start processing incoming 
          </button>
        </MenuItem>

        <MenuItem className={classes.li}>
          <button
            onClick={() => {
              outgoing();
            }}
            className={classes.button}
          >
            Start processing outgoing 
          </button>
        </MenuItem>

      </Menu>
    </div>
  );
};

HeaderMenu.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(
  connect(
    null,
    { logout,incoming,mint,outgoing }
  )(HeaderMenu)
);
