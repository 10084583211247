import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, getTime } from 'date-fns';

import { calculateTimezoneOffset } from '../../../utils/time.utils';
import TextInput from '../../../components/TextInput';
import AddButton from '../../../components/AddButton';
import InfoMessage from '../InfoMessage';
import { addNewComment } from '../../../actions/users';

import './Comments.scss';

const Comments = ({ username, comments, getDetails }) => {
  const [newComment, setNewComment] = useState('');
  const [error, setError] = useState('');

  const submitComment = async event => {
    event.preventDefault();
    if (!newComment) {
      setError('You must add a comment first');
      return;
    }
    try {
      setError('');
      await addNewComment(username, newComment);
      getDetails(username);
      setNewComment('');
    } catch (error) {
      setError(error);
    }
  };

  const renderComments = () => {
    if (comments.length === 0) {
      return (
        <div className="compliance__comments-no-comments">
          No Comments found
        </div>
      );
    }

    const sortedComments = comments.sort(
      (prev, next) => Number(getTime(next.time)) - Number(getTime(prev.time))
    );

    return (
      <div className="compliance__comments__list">
        {sortedComments.map((singleComment, index) => {
          const { time, userFullName, comment, userId } = singleComment;

          const formattedTime = format(
            calculateTimezoneOffset(time),
            'DD.MM.YYYY H:mm'
          );
          const formattedName = `${userFullName || userId} ${formattedTime}`;
          return (
            <InfoMessage
              key={`${index}${comment.time}`}
              name={formattedName}
              message={comment}
            />
          );
        })}
      </div>
    );
  };
  return (
    <div className="compliance__comments">
      <form onSubmit={submitComment}>
        <TextInput
          name="comment"
          label="Comment"
          placeholder="Enter New Comment"
          onChange={event => {
            setNewComment(event.target.value);
          }}
          value={newComment}
        />
        <AddButton type="submit" />
      </form>
      {error && <div className="compliance__comments__error">{error}</div>}
      {renderComments()}
    </div>
  );
};

Comments.propTypes = {
  username: PropTypes.string,
  comments: PropTypes.array,
  getDetails: PropTypes.func
};

export default Comments;
