import { SET_TRANSACTION_STATUS, RESET_TRANSACTION_STATUS } from '../actions/types';

const initialState = {
  value: '',
  message: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_STATUS:
      return action.payload;
    case RESET_TRANSACTION_STATUS:
      return action.payload;
    default:
      return state;
  }
};
