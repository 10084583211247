import {
  AUTH_USER,
  AUTH_ERROR,
  AUTH_SET_ROLES,
  AUTH_CLEAR_ROLES
} from '../actions/types';

const initialState = {
  isAuthenticated: !!sessionStorage.getItem('access_token'),
  roles: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        isAuthenticated: action.payload,
        errorMessage: ''
      };
    case AUTH_CLEAR_ROLES: {
      return { ...state, roles: [] };
    }
    case AUTH_SET_ROLES: {
      return {
        ...state,
        roles: action.payload
      };
    }
    case AUTH_ERROR:
      return {
        ...state,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
