import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Paper, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import { getMetamaskAccount } from '../../../utils/metamask.utils';
import {
  updateCapInStore,
  setTransactionStatus,
  resetTransactionStatus
} from '../../../actions/tokens';
import addCash36 from '../../../Cash36Provider';
import StyledButton from '../../../components/StyledButton';
import TransactionSuccess from '../TransactionSuccess';
import TransactionError from '../TransactionError';
import TransactionPending from '../TransactionPending';

import styles from './styles';

const UpdateTokenCap = ({
  classes,
  web3,
  cash36Contract,
  symbol,
  cap,
  decimals,
  transactionStatus,
  setTransactionStatus,
  resetTransactionStatus,
  updateCapInStore,
  getCallbacks
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleClose = () => {
    if (transactionStatus.value === '') {
      setAnchorEl(null);
    }
  };

  const handleUpdateTokenCapClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateTokenCapSubmit = async () => {
    setTransactionStatus({
      value: 'pending',
      message: ''
    });
    try {
      await UpdateTokenCapOnBlockchain(symbol, inputValue);
    } catch (error) {
      setTransactionStatus({
        value: 'error',
        message: 'There was an error with the request'
      });
    }
  };

  const handleTransactonSuccess = () => {
    resetTransactionStatus();
    updateCapInStore(symbol, inputValue);
    setAnchorEl(null);
    setInputValue('');
  };

  const handleTransactionFail = () => {
    resetTransactionStatus();
    setAnchorEl(null);
  };

  const UpdateTokenCapOnBlockchain = async (symbol, newCapValue) => {
    const address = await getMetamaskAccount();

    if (address) {
      let updatedCap = web3.utils.toWei(newCapValue);
      const estimate = await cash36Contract.methods
        .updateCap(symbol, updatedCap)
        .estimateGas({ from: address });

      const data = cash36Contract.methods
        .updateCap(symbol, updatedCap)
        .encodeABI();

      const options = {
        from: address,
        to: cash36Contract.options.address,
        gas: estimate + Math.round(estimate * 0.1),
        nonce: await web3.eth.getTransactionCount(address, 'pending'),
        data
      };

      const callbacks = getCallbacks('updateCap');

      return web3.eth
        .sendTransaction(options)
        .once('transactionHash', callbacks.transactionHash)
        .on('receipt', callbacks.receipt)
        .on('error', callbacks.error);
    } else {
      setTransactionStatus({
        value: 'error',
        message: 'You need to log in to MetaMask'
      });
    }
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <StyledButton variant="text" onClick={handleUpdateTokenCapClick}>
        <EditIcon className={classes.icon} />
      </StyledButton>
      <Dialog open={open} onClose={handleClose}>
        <Paper className={classes.paper}>
          {transactionStatus.value === 'pending' && <TransactionPending />}
          {transactionStatus.value === 'success' && <TransactionSuccess />}
          {transactionStatus.value === 'mined' && (
            <Fragment>
              <Typography variant="h6">Token Cap Updated</Typography>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleTransactonSuccess}
              >
                OK
              </StyledButton>
            </Fragment>
          )}
          {transactionStatus.value === 'error' && (
            <TransactionError
              errorMessage={transactionStatus.message}
              handleTransactionFail={handleTransactionFail}
            />
          )}
          {transactionStatus.value === '' && (
            <Fragment>
              <Typography variant="h6">Update Token Cap</Typography>
              <Typography variant="subtitle1"> Current cap: {cap}</Typography>
              <TextField
                placeholder="New token cap"
                value={inputValue}
                type="number"
                onChange={event => {
                  setInputValue(event.target.value);
                }}
                margin="normal"
                fullWidth
              />
              <StyledButton
                disabled={!inputValue}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleUpdateTokenCapSubmit}
              >
                Submit new value
              </StyledButton>
            </Fragment>
          )}
        </Paper>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ transactionStatus }) => ({ transactionStatus });
const mapDispatchToProps = {
  updateCapInStore,
  setTransactionStatus,
  resetTransactionStatus
};

UpdateTokenCap.propTypes = {
  classes: PropTypes.object,
  transactionStatus: PropTypes.object,
  setTransactionStatus: PropTypes.func,
  resetTransactionStatus: PropTypes.func,
  updateCapInStore: PropTypes.func,
  symbol: PropTypes.string,
  decimals: PropTypes.string,
  getCallbacks: PropTypes.func,
  cash36Contract: PropTypes.object,
  web3: PropTypes.object,
  cap: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default addCash36(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(UpdateTokenCap))
);
