import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from '../../../components/StyledButton';

const ActiveActions = ({
  currentProcessStatus,
  handleConfirmClick,
  handleDenyClick,
  roles,
  confirmedByComplianceSu,
  confirmedByMgmt,
  status
}) => {
  if (
    (roles.compliance || roles.complianceSu) &&
    currentProcessStatus !== 'CLOSED'
  ) {
    return (
      <>
        <StyledButton
          disabled={currentProcessStatus !== 'AWAITING_VERIFICATION'}
          onClick={handleConfirmClick}
        >
          {roles.management && roles.compliance
            ? 'Confirm by compliance and MGMT'
            : 'Confirm User'}
        </StyledButton>
        <StyledButton
          disabled={currentProcessStatus !== 'AWAITING_VERIFICATION'}
          onClick={handleDenyClick}
        >
          Deny User
        </StyledButton>
      </>
    );
  }

  if (
    currentProcessStatus === 'CLOSED' &&
    status &&
    roles.complianceSu &&
    !confirmedByComplianceSu
  ) {
    return (
      <StyledButton onClick={handleConfirmClick}>Confirmed by GWP</StyledButton>
    );
  }

  if (
    currentProcessStatus !== 'CLOSED' &&
    roles.management &&
    !confirmedByMgmt
  ) {
    return (
      <StyledButton onClick={handleConfirmClick}>
        Confirmed by MGMT
      </StyledButton>
    );
  }

  return null;
};

ActiveActions.propTypes = {
  currentProcessStatus: PropTypes.string,
  handleConfirmClick: PropTypes.func,
  handleDenyClick: PropTypes.func,
  roles: PropTypes.object,
  status: PropTypes.bool,
  confirmedByMgmt: PropTypes.bool,
  confirmedByComplianceSu: PropTypes.bool
};

export default ActiveActions;
