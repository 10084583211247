import React, { useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MenuItem, TextField } from '@material-ui/core';

import './FilterByStatus.scss';

const filters = [
  { value: '', label: 'All' },
  { value: 'OPEN', label: 'Open' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'ON_HOLD', label: 'On Hold' },
  { value: 'RELEASED', label: 'Released' }
];

const FilterByStatus = ({ onChangeCallback }) => {
  const [filter, setFilter] = useState('');

  const onChange = event => {
    setFilter(event.target.value);
    onChangeCallback(event.target.value);
  };

  return (
    <div className="history__filter-by paper">
      <span>Filter by status:</span>
      <TextField
        name="filterByStatus"
        select
        value={filter}
        onChange={onChange}
        fullWidth
        InputProps={{
          disableUnderline: true,
          className: 'history__filter-by__input',
          style: {
            fontSize: '1.6rem'
          }
        }}
        SelectProps={{
          displayEmpty: true,
          IconComponent: KeyboardArrowDownIcon
        }}
      >
        {filters.map(filter => (
          <MenuItem key={filter.value} value={filter.value}>
            {filter.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

FilterByStatus.propTypse = {
  onChangeCallback: PropTypes.func
};

export default FilterByStatus;
