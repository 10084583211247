import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './FileInput.scss';

const FileInput = ({ changeCallback, removeCallback, index, username }) => {
  const [progress, setProgress] = useState(0);
  const [name, setName] = useState(null);
  const [error, setError] = useState(false);
  const fileInput = useRef();

  const handleChange = evt => {
    const { files } = evt.target;
    const fileTypes = ['png', 'jpg', 'jpeg', 'pdf'];

    if (!files[0]) return;

    const isValidFileType = fileTypes.includes(
      files[0].name
        .split('.')
        .pop()
        .toLowerCase()
    );

    setProgress(0);
    setName(isValidFileType ? files[0].name : null);
    setError(!isValidFileType);

    if (!isValidFileType) {
      removeCallback(index);
      return;
    }

    let reader = new FileReader();

    reader.onprogress = data => {
      if (data.lengthComputable) {
        let progress = parseInt((data.loaded / data.total) * 100, 10);
        setProgress(progress);
      }
    };
    reader.readAsDataURL(files[0]);

    changeCallback(files[0], index);
  };

  useEffect(() => {
    fileInput.current.value = '';
    setName(null);
  }, [username]);

  return (
    <div className="tier2-form__file-input__container">
      <label
        className={`tier2-form__file-input ${name ? 'active' : ''} ${
          error ? 'error' : ''
        }`}
      >
        <div className="tier2-form__file-input__body">
          <i className="fas fa-file" />
          {!name ? (
            <span>Upload a document or an image</span>
          ) : (
            <span>{name}</span>
          )}
        </div>
        <span className="tier2-form__file-input__upload-icon">
          <i className="fas fa-upload" />
        </span>
        {name && (
          <div className="tier2-form__file-input__progress">
            <span style={{ width: `${progress}%` }} />
          </div>
        )}
        <input type="file" ref={fileInput} onChange={handleChange} />
      </label>
      {error && <p>Only pdfs,jpeg, jpg and png files are allowed</p>}
    </div>
  );
};

FileInput.propTypes = {
  changeCallback: PropTypes.func,
  removeCallback: PropTypes.func,
  index: PropTypes.number,
  username: PropTypes.string
};

export default FileInput;
