import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import DropdownHeader from './DropdownHeader';
import './Dropdown.scss';

const Dropdown = ({ title, children, disableDropdown = false }) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [isActiveContent, setActiveConent] = useState(false);
  const dropdownContent = useRef(null);

  const showContent = () => {
    if (!disableDropdown) {
      if (contentHeight) {
        setActiveConent(false);
        setTimeout(() => setContentHeight(0), 0);
      } else {
        setContentHeight(dropdownContent.current.clientHeight);
        setTimeout(() => setActiveConent(true), 300);
      }
    }
  };

  return (
    <div className="dropdown" data-testid="dropdown">
      <DropdownHeader
        title={title}
        onClick={showContent}
        isActive={contentHeight}
        disableDropdown={disableDropdown}
      />
      <div
        className="dropdown__content-wrapper"
        style={{
          height: isActiveContent ? 'auto' : contentHeight
        }}
      >
        <div className="dropdown__content" ref={dropdownContent}>
          {children}
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any
};

export default Dropdown;
