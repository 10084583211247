import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { saveUserProfileVerification } from '../../../actions/users';
import StyledButton from '../../../components/StyledButton';
import TextInput from '../../../components/TextInput';
import StatusIcon from '../../../components/StatusIcon';

import './UserProfileVerification.scss';

const UserProfileVerification = ({ user, getDetails }) => {
  const [plausibleProfileRemarks, setPlausibleProfileRemarks] = useState('');
  const [plausibleProfile, setPlausibleProfile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const {
    username,
    checklist: { profileVerificationDone }
  } = user;

  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  });

  const onRadioChange = useCallback(event => {
    setPlausibleProfile(event.target.value);
  }, []);

  const save = async () => {
    setSubmitting(true);
    setError('');

    try {
      console.log('Is profile plausible?', plausibleProfile === 'yes');
      await saveUserProfileVerification(
        username,
        plausibleProfile === 'yes',
        plausibleProfileRemarks
      );
      getDetails(username);
      if (_isMounted) setSubmitting(false);
    } catch (error) {
      setError(error);

      if (_isMounted) setSubmitting(false);
    }
  };

  if (profileVerificationDone) {
    return (
      <div className="compliance__user-verification__done">
        <div>
          Profile {user.isPlausibleProfile ? 'is' : 'is not'} plausible{' '}
          <StatusIcon valid={user.isPlausibleProfile} />
        </div>

        <span className="mrz-data__no-scan-run">
          {user.plausibleProfileRemarks}
        </span>
      </div>
    );
  }

  return (
    <div className="compliance__user-verification">
      <div>
        <TextInput
          name="user-profile-verification"
          value={plausibleProfileRemarks}
          onChange={event => {
            setPlausibleProfileRemarks(event.target.value);
          }}
          label={'Verification of User Profile'}
          placeholder={'Comment your judgement regarding the User Profile'}
          multiline
        />
      </div>

      <div className="compliance__user-verification__radio">
        <div>Is User Profile plausible?</div>
        <RadioGroup
          value={plausibleProfile}
          onChange={onRadioChange}
          className="compliance__user-verification__radio-group"
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            labelPlacement="start"
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            labelPlacement="start"
            label="No"
          />
        </RadioGroup>
        <div className="compliance__save-result__button">
          <StyledButton
            onClick={save}
            disabled={
              submitting || (!plausibleProfile || !plausibleProfileRemarks)
            }
          >
            Save Result
          </StyledButton>
          {error && <div className="error-text">{error}</div>}
        </div>
      </div>
    </div>
  );
};

UserProfileVerification.propTypes = {
  user: PropTypes.object,
  getDetails: PropTypes.func
};

export default UserProfileVerification;
