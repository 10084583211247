import React from 'react';
import PropTypes from 'prop-types';
import { withWeb3 } from '../Web3Provider';

const childContextTypes = {
  network: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  })
};

class NetworkProvider extends React.Component {
    static contextTypes = {
      store: PropTypes.object
    };

    constructor (props, context) {
      super(props, context);

      this.state = {
        loading: true,
        networkId: null,
        networkError: false
      };

      this.setNetwork.bind(this);
    }

    getChildContext () {
      return {
        network: {
          id: this.state.networkId,
          type: this.getNetwork(this.state.networkId)
        }
      };
    }

    componentDidMount () {
      this.setNetwork();
    }

    setNetwork () {
      const { web3 } = this.props;

      return web3.eth.net.getId().then((id) => {
        console.log('set network ' + id);
        this.setState({
          networkId: id,
          loading: false
        });
        return id;
      }).catch(error => {
        console.log('Error: web3 not available ' + error);
        this.setState({
          networkError: true,
          loading: false
        });
        return null;
      });
    }

    getNetwork (networkId) {
      switch (networkId) {
        case 1:
          return 'MainNet';
        case 2:
          return 'Morden';
        case 3:
          return 'Ropsten';
        case 4:
          return 'Rinkeby';
        case 85458545:
          return 'Local';
        default:
          return 'unknown';
      }
    }

    render () {
      if (this.state.loading) {
        return <div className="loading-full">Connecting to Ethereum node...</div>;
      } else if (this.state.networkError) {
        return <div className="error-full">Error connecting to Ethereum node!</div>;
      } else {
        return this.props.children;
      }
    }
}

NetworkProvider.propTypes = {
  children: PropTypes.any
};

NetworkProvider.childContextTypes = childContextTypes;

export default withWeb3(NetworkProvider);
