import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Switch } from '@material-ui/core';

const AttrItem = ({ attr, onChange, username }) => {
  const [updateProgress, setUpdateProgress] = useState(false);

  const handleChange = async e => {
    setUpdateProgress(true);
    try {
      await onChange(attr.name, e.target.checked);
    } catch (error) {}
    setUpdateProgress(false);
  };

  useEffect(() => {
    setUpdateProgress(false);
  }, [username]);

  return (
    <div className="comliance-user-attr-item">
      <span>
        {attr.name.split('_')[1]}
        {updateProgress && <CircularProgress size={15} />}
      </span>
      <Switch
        checked={attr.active}
        onChange={handleChange}
        value={1}
        color="primary"
        disabled={updateProgress}
      />
    </div>
  );
};

AttrItem.propTypes = {
  attr: PropTypes.object,
  onChange: PropTypes.func,
  username: PropTypes.string
};

export default AttrItem;
