export default {
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
    fontSize: '2.1rem'
  },
  text: {
    marginRight: '1rem'
  }
};
