import accents from 'remove-accents';
import { getFullDate } from '../../../utils/mrz.utils';
import { parse as parseDate, differenceInDays, isSameDay } from 'date-fns';
import { mrzCountries } from './mrzCountries';
import { getName } from 'country-list';

/**
 * Checks if two strings passed in are equal, including special characters caes
 * @param {string} mrzName
 * @param {string} userName
 * @return {boolean}
 */
export function validateName (mrzName, userName) {
  // regular case where names are the same
  if (mrzName.toLowerCase().trim() === userName.toLowerCase().trim()) {
    return true;
  }

  // if there are several names, validate only the first
  if (
    mrzName
      .split(' ')[0]
      .toLowerCase()
      .trim() ===
    userName
      .split(' ')[0]
      .toLowerCase()
      .trim()
  ) {
    return true;
  }

  // if there are special chars in names
  if (accents.has(mrzName) || accents.has(userName)) {
    // one name case
    if (accents.remove(mrzName) === accents.remove(userName)) {
      return true;
    }
    // several names case
    if (
      accents
        .remove(mrzName)
        .split(' ')[0]
        .toLowerCase()
        .trim() ===
      accents
        .remove(userName)
        .split(' ')[0]
        .toLowerCase()
        .trim()
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Checks if expiration date has passed
 * @param {string} mrzExpirationDate
 * @return {boolean}
 */

export function validateExpirationDate (mrzExpirationDate) {
  const fullDate = getFullDate(mrzExpirationDate);

  const parsedDate = parseDate(
    `${fullDate.month}/${fullDate.day}/${fullDate.year}`,
    'MM/DD/YYYY'
  );

  const now = new Date();

  const mrzDateValid = differenceInDays(parsedDate, now) > 0;

  return mrzDateValid;
}

/**
 * Checks if date of birth is valid
 * @param {string} mrzDob
 * @param {string} userDob
 * @return {boolean}
 */

export function validateDateOfBirth (mrzDob, userDob) {
  // parse Mrz date of birth
  const fullMrzDob = getFullDate(mrzDob);
  const parsedMrzDob = parseDate(
    `${fullMrzDob.month}/${fullMrzDob.day}/${fullMrzDob.year}`,
    'MM/DD/YYYY'
  );
  // parse user date of birth
  const fullUserDob = userDob.split('.');
  const parsedUserDob = parseDate(
    `${fullUserDob[1]}/${fullUserDob[0]}/${fullUserDob[2]}`,
    'MM/DD/YYYY'
  );

  const dobValid = isSameDay(parsedMrzDob, parsedUserDob);

  return dobValid;
}

/**
 * Checks if nationality is valid
 * @param {string} mrzNationality
 * @param {string} userNationality
 * @return {boolean}
 */

export function validateNationality (mrzNationality, userNationality) {
  const mrzCountryName = mrzCountries[mrzNationality];

  const userCountryName = getName(userNationality);

  const nationalityValid =
    mrzCountryName.toLowerCase().trim() ===
    userCountryName.toLowerCase().trim();

  return nationalityValid;
}

/**
 * Performs the verification of the string to match the check digit.
 * This is used to check the validity of the value of the string to check for any counter-fit issues.
 *
 * @param str The value to perform the validation on
 * @param digit The value of the check digit which is to be compared to the result of the algorithm
 * @returns {boolean} Whether the computed result of the value specified matches the check digit value
 */

/* VALUES FOR EACH LETTER, USED TO PERFORM THE CHECK DIGITS VERIFICATION */
const _checkDigitValues = [];
_checkDigitValues['<'] = 0;
_checkDigitValues['A'] = 10;
_checkDigitValues['B'] = 11;
_checkDigitValues['C'] = 12;
_checkDigitValues['D'] = 13;
_checkDigitValues['E'] = 14;
_checkDigitValues['F'] = 15;
_checkDigitValues['G'] = 16;
_checkDigitValues['H'] = 17;
_checkDigitValues['I'] = 18;
_checkDigitValues['J'] = 19;
_checkDigitValues['K'] = 20;
_checkDigitValues['L'] = 21;
_checkDigitValues['M'] = 22;
_checkDigitValues['N'] = 23;
_checkDigitValues['O'] = 24;
_checkDigitValues['P'] = 25;
_checkDigitValues['Q'] = 26;
_checkDigitValues['R'] = 27;
_checkDigitValues['S'] = 28;
_checkDigitValues['T'] = 29;
_checkDigitValues['U'] = 30;
_checkDigitValues['V'] = 31;
_checkDigitValues['W'] = 32;
_checkDigitValues['X'] = 33;
_checkDigitValues['Y'] = 34;
_checkDigitValues['Z'] = 35;

export function checkDigitVerify (str, digit) {
  let nmbrs = [];
  let weighting = [7, 3, 1];
  for (let i = 0; i < str.length; i++) {
    if (str[i].match(/[A-Za-z<]/)) {
      nmbrs.push(_checkDigitValues[str[i]]);
    } else {
      nmbrs.push(parseInt(str[i]));
    }
  }

  let curWeight = 0;
  let total = 0;
  for (let j = 0; j < nmbrs.length; j++) {
    total += nmbrs[j] * weighting[curWeight];
    curWeight++;
    if (Number(curWeight) === 3) {
      curWeight = 0;
    }
  }

  return Number(total) % 10 === Number(digit);
}
