import React from 'react';
import PropTypes from 'prop-types';
import './Ammount.scss';

const Amount = ({ amount, symbol }) => (
  <div className="user-orders__amount">
    <span>{amount && amount.toFixed(2)}</span> <span>{symbol}</span>
  </div>
);

Amount.propTypes = {
  classes: PropTypes.object,
  amount: PropTypes.number
};

export default Amount;
