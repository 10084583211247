import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';

import { getMetamaskAccount } from '../../../utils/metamask.utils';
import {
  removeExchangeFromStore,
  setTransactionStatus,
  resetTransactionStatus
} from '../../../actions/tokens';
import addCash36 from '../../../Cash36Provider';
import StyledButton from '../../../components/StyledButton';
import TransactionSuccess from '../TransactionSuccess';
import TransactionError from '../TransactionError';
import TransactionPending from '../TransactionPending';

import styles from './styles';

const RemoveExchange = ({
  classes,
  web3,
  transactionStatus,
  setTransactionStatus,
  exchangeAddress,
  tokenAddress,
  cash36ExchangesContract,
  resetTransactionStatus,
  getCallbacks,
  removeExchangeFromStore
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    if (transactionStatus.value === '') {
      setAnchorEl(null);
    }
  };

  const handleRemoveExchangeClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleRemoveExchangeSubmit = async () => {
    setTransactionStatus({
      value: 'pending',
      message: ''
    });
    try {
      await removeExchangeFromBlockchain(exchangeAddress, tokenAddress);
    } catch (error) {
      console.log(error);
      setTransactionStatus({
        value: 'error',
        message: 'There was an error with the request'
      });
    }
  };

  const removeExchangeFromBlockchain = async (
    exchangeAddress,
    tokenAddress
  ) => {
    const address = await getMetamaskAccount();

    console.log(address);

    if (address) {
      const estimate = await cash36ExchangesContract.methods
        .removeExchange(exchangeAddress, tokenAddress)
        .estimateGas({ from: address });

      const data = cash36ExchangesContract.methods
        .removeExchange(exchangeAddress, tokenAddress)
        .encodeABI();

      const options = {
        from: address,
        to: cash36ExchangesContract.options.address,
        gas: estimate + Math.round(estimate * 0.1),
        nonce: await web3.eth.getTransactionCount(address, 'pending'),
        data
      };

      const callbacks = getCallbacks('removeExchange');

      return web3.eth
        .sendTransaction(options)
        .once('transactionHash', callbacks.transactionHash)
        .on('receipt', callbacks.receipt)
        .on('error', callbacks.error);
    } else {
      setTransactionStatus({
        value: 'error',
        message: 'You need to log in to MetaMask'
      });
    }
  };

  const handleTransactonSuccess = () => {
    resetTransactionStatus();
    removeExchangeFromStore(exchangeAddress, tokenAddress);
    setAnchorEl(null);
  };

  const handleTransactionFail = () => {
    setAnchorEl(null);
    resetTransactionStatus();
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <StyledButton
        onClick={handleRemoveExchangeClick}
        className={classes.button}
      >
        <Delete color="error" />
      </StyledButton>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <Paper className={classes.paper}>
          {transactionStatus.value === 'pending' && <TransactionPending />}
          {transactionStatus.value === 'success' && <TransactionSuccess />}
          {transactionStatus.value === 'mined' && (
            <Fragment>
              <Typography variant="h6">Exchange Removed</Typography>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleTransactonSuccess}
              >
                OK
              </StyledButton>
            </Fragment>
          )}
          {transactionStatus.value === 'error' && (
            <TransactionError
              errorMessage={transactionStatus.message}
              handleTransactionFail={handleTransactionFail}
            />
          )}
          {transactionStatus.value === '' && (
            <Fragment>
              <Typography variant="h6" gutterBottom>
                Remove the Exchange
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {exchangeAddress}
              </Typography>
              <StyledButton
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleRemoveExchangeSubmit}
              >
                Remove Exchange
              </StyledButton>
            </Fragment>
          )}
        </Paper>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ transactionStatus }) => ({ transactionStatus });

RemoveExchange.propTypes = {
  classes: PropTypes.object,
  transactionStatus: PropTypes.object,
  removeExchangeFromStore: PropTypes.func,
  setTransactionStatus: PropTypes.func,
  resetTransactionStatus: PropTypes.func,
  getCallbacks: PropTypes.func,
  cash36ComplianceContract: PropTypes.object,
  web3: PropTypes.object
};

export default addCash36(
  connect(
    mapStateToProps,
    {
      removeExchangeFromStore,
      setTransactionStatus,
      resetTransactionStatus
    }
  )(withStyles(styles)(RemoveExchange))
);
