import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withWeb3 } from './components/Web3Provider';
import {
  Cash36Contract,
  Token36Contract,
  Cash36ComplianceContract,
  Cash36ExchangesContract
} from '@element36-io/cash36-contracts';

import { setTransactionStatus } from './actions/tokens';

/**
 * HOC for cash36. Add cash36 contracts to the wrapped component.
 *
 * @param WrappedComponent
 * @returns {*}
 */
const addCash36 = WrappedComponent => {
  class Cash36Provider extends Component {
    constructor (props, context) {
      super(props, context);

      const { web3 } = this.props;
      const { id: networkId } = context.network;

      let cash36Contract = null;
      let cash36ComplianceContract = null;
      let cash36ExchangesContract = null;
      const networkError = Cash36Contract.networks[networkId]
        ? null
        : 'Wrong network, please change MetaMask network';

      if (Cash36Contract.networks[networkId]) {
        // Load instance of cash36 Contract
        cash36Contract = this.getInstance(
          Cash36Contract.abi,
          Cash36Contract.networks[networkId].address,
          web3
        );
        cash36ComplianceContract = this.getInstance(
          Cash36ComplianceContract.abi,
          Cash36ComplianceContract.networks[networkId].address,
          web3
        );
        cash36ExchangesContract = this.getInstance(
          Cash36ExchangesContract.abi,
          Cash36ExchangesContract.networks[networkId].address,
          web3
        );
      }

      this.state = {
        web3,
        networkId,
        networkError,
        loading: true,
        cash36Contract: cash36Contract,
        cash36ComplianceContract: cash36ComplianceContract,
        cash36ExchangesContract: cash36ExchangesContract
      };
    }

    /**
     * Utils to get Instance of an existing contract
     *
     * @param abi
     * @param address
     * @returns {*}
     */
    getInstance (abi, address, web3) {
      const contract = new web3.eth.Contract(abi, address);
      contract.setProvider(web3.currentProvider);

      return contract;
    }

    getTokenInstance (address) {
      const token = this.getInstance(
        Token36Contract.abi,
        address,
        this.state.web3
      );
      token.setProvider(this.state.web3.currentProvider);

      return token;
    }

    getCallbacks (action) {
      return {
        receipt: this.receiptCallback(action).bind(this),
        transactionHash: this.transactionHashCallback(action).bind(this),
        error: this.errorCallback(action).bind(this)
      };
    }

    transactionHashCallback (action) {
      return hash => {
        console.log('transactionHashCallback - ' + action);
        // this.props.dispatch(info(Messages.transactionSent(action)));
        this.props.setTransactionStatus({
          value: 'success',
          message: action
        });
      };
    }

    receiptCallback (action) {
      return receipt => {
        console.log('receiptCallback - ' + action);
        // this.props.dispatch(success(Messages.transactionMined(action)));
        this.props.setTransactionStatus({
          value: 'mined',
          message: action
        });
      };
    }

    errorCallback (action) {
      return async err => {
        // this.props.dispatch(error(Messages.error(action, err.message)));
        console.log(`ERROR: ${err.message}`);
        this.props.setTransactionStatus({
          value: 'error',
          message: err.message
        });
      };
    }

    render () {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          getTokenInstance={this.getTokenInstance.bind(this)}
          getCallbacks={this.getCallbacks.bind(this)}
        />
      );
    }
  }

  Cash36Provider.contextTypes = {
    network: PropTypes.object
  };

  const mapDispatchToProps = { setTransactionStatus };

  return connect(
    null,
    mapDispatchToProps
  )(withWeb3(Cash36Provider));
};

export default addCash36;
