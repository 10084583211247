export default theme => ({
  paper: {
    minWidth: '35rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  icon: {
    color: theme.palette.greys.darkGrey
  }
});
