export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.greys.lightGrey,
    fontSize: '1.4rem',
    color: theme.palette.greys.darkGrey,
    width: '5rem',
    height: '5rem',
    position: 'relative',
    overflow: 'visible',
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 20,
    marginLeft: '2rem'
  },
  icon: {
  }
});
