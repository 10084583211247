import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withWeb3 } from '../../../components/Web3Provider';

import { getExchangeBalance } from '../../../actions/tokens';

const ExchangeBalance = ({
  balance,
  exchangeAddress,
  tokenAddress,
  web3,
  getExchangeBalance
}) => {
  const getBalance = () => {
    getExchangeBalance(exchangeAddress, tokenAddress, getBalanceFromEthereum);
  };

  const getBalanceFromEthereum = async () => {
    try {
      let balance = await web3.eth.getBalance(exchangeAddress);
      balance = await web3.utils.fromWei(balance, 'ether');

      return balance;
    } catch (error) {
      console.log(error); // TODO handle the error
    }
  };

  return (
    <Fragment>
      {balance ? ` ${balance.substring(0, 4)} ETH` : getBalance()}
    </Fragment>
  );
};

const mapStateToProps = (
  { tokens: { tokens } },
  { exchangeAddress, tokenAddress }
) => {
  const exchange = tokens
    .filter(token => token.address === tokenAddress)[0]
    .allowedExchanges.filter(
      exchange => exchange.exchangeAddress === exchangeAddress
    )[0];

  return {
    balance: exchange.balance
  };
};

ExchangeBalance.propTypes = {
  balance: PropTypes.string,
  getExchangeBalance: PropTypes.func,
  web3: PropTypes.object
};

export default connect(
  mapStateToProps,
  { getExchangeBalance }
)(withWeb3(ExchangeBalance));
