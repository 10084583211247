import React from 'react';
import PropTypes from 'prop-types';
import ActionButtons from '../ActionButtons';
import Checklist from '../Checklist';

import './UserDetailsBottom.scss';

const UserDetailsBottom = ({ user, getDetails, status }) => {
  const { checklist, riskEvaluation } = user;

  return (
    <div className="user-details-bottom">
      <div>
        <h3>Final Identity Confirmation Checklist</h3>
        <Checklist list={checklist} riskEvaluation={riskEvaluation} />
      </div>

      <ActionButtons
        currentProcessStatus={user.currentProcessStatus}
        username={user.username}
        getDetails={getDetails}
        status={status}
        checklist={checklist}
        riskLevel={riskEvaluation ? riskEvaluation.riskLevel : null}
        confirmedByComplianceSu={user.confirmedByComplianceSu}
        confirmedByMgmt={user.confirmedByMgmt}
      />
    </div>
  );
};

UserDetailsBottom.propTypes = {
  user: PropTypes.object,
  getDetails: PropTypes.func,
  status: PropTypes.bool
};

export default UserDetailsBottom;
