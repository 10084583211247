import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTime, format } from 'date-fns';
import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { calculateTimezoneOffset } from '../../../utils/time.utils';
import { getUserHistory } from '../../../actions/users';
import Checklist from '../Checklist';

import './History.scss';

const History = ({ username, close, checklist, riskEvaluation }) => {
  const [userHistory, setUserHistory] = useState([]);
  const [fetching, setFetching] = useState(true);

  const fetchData = async () => {
    try {
      const response = await getUserHistory(username);
      setUserHistory(response);
    } catch (error) {
      console.warn(error);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sortedHistory = userHistory.sort(
    (prev, next) => Number(getTime(next.time)) - Number(getTime(prev.time))
  );

  if (fetching) {
    return (
      <div className="compliance__history">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="compliance__history">
      <CloseIcon onClick={close} />
      <h2>History</h2>
      {!userHistory && !fetching && (
        <div className="compliance__history-no-history">
          No History items found.
        </div>
      )}
      {userHistory && (
        <div className="compliance__history__row --alt">
          <div>When</div>
          <div>Action</div>
          <div>Who</div>
        </div>
      )}
      {sortedHistory.map((historyField, index) => {
        const { action, remarks, role, time, user } = historyField;
        return (
          <div className="compliance__history__row" key={index + time}>
            <div>
              {format(calculateTimezoneOffset(time), 'DD.MM.YYYY H:mm')}
            </div>
            <div>
              {action}
              {remarks && <span>{remarks}</span>}
              {action === 'UPGRADED TO TIER-2' && (
                <Checklist list={checklist} riskEvaluation={riskEvaluation} />
              )}
            </div>
            <div>
              {user} (<span>{role.toLowerCase()}</span>)
            </div>
          </div>
        );
      })}
    </div>
  );
};

History.propTypes = {
  username: PropTypes.string,
  checklist: PropTypes.object,
  riskEvaluation: PropTypes.object,
  close: PropTypes.func
};

export default History;
