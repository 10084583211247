export default theme => ({
  root: {
    fontSize: '1.6rem',
    '& input': {
      fontSize: '1.6rem',
      '&::placeholder': {
        color: '#01152C',
        opacity: '0.5'
      }
    }
  }
});
