import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Paper, Typography, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { getMetamaskAccount } from '../../../utils/metamask.utils';
import {
  toggleTransfersInStore,
  setTransactionStatus,
  resetTransactionStatus
} from '../../../actions/tokens';
import addCash36 from '../../../Cash36Provider';
import StyledButton from '../../../components/StyledButton';
import TransactionSuccess from '../TransactionSuccess';
import TransactionError from '../TransactionError';
import TransactionPending from '../TransactionPending';

import styles from './styles';

const ToggleTransfers = ({
  classes,
  web3,
  cash36Contract,
  symbol,
  transfersEnabled,
  exchangeAddress,
  transactionStatus,
  setTransactionStatus,
  toggleTransfersInStore,
  resetTransactionStatus,
  getCallbacks
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSwitchToggle = async event => {
    setAnchorEl(event.currentTarget);
    try {
      await toggleTransfers(symbol, !transfersEnabled);
    } catch (error) {
      setTransactionStatus({
        value: 'error',
        message: 'Request was denied'
      });
    }
  };

  const handleTransactonSuccess = () => {
    setAnchorEl(null);
    toggleTransfersInStore(symbol, !transfersEnabled);
    resetTransactionStatus();
  };

  const handleTransactionFail = () => {
    setAnchorEl(null);
    resetTransactionStatus();
  };

  const toggleTransfers = async (symbol, boolEnabled) => {
    const address = await getMetamaskAccount();
    if (address) {
      const estimate = await cash36Contract.methods
        .enableTransfers(symbol, boolEnabled)
        .estimateGas({ from: address });

      const data = cash36Contract.methods
        .enableTransfers(symbol, boolEnabled)
        .encodeABI();

      const options = {
        from: address,
        to: cash36Contract.options.address,
        gas: estimate + Math.round(estimate * 0.1),
        nonce: await web3.eth.getTransactionCount(address, 'pending'),
        data
      };

      const callbacks = getCallbacks('enableTransfers');

      return web3.eth
        .sendTransaction(options)
        .once('transactionHash', callbacks.transactionHash)
        .on('receipt', callbacks.receipt)
        .on('error', callbacks.error);
    } else {
      setTransactionStatus({
        value: 'error',
        message: 'You need to log in to MetaMask'
      });
    }
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <Switch checked={transfersEnabled} onChange={handleSwitchToggle} />
      <Dialog open={open}>
        <Paper className={classes.paper}>
          {transactionStatus.value === 'pending' && <TransactionPending />}
          {transactionStatus.value === 'success' && <TransactionSuccess />}
          {transactionStatus.value === 'mined' && (
            <Fragment>
              <Typography variant="h6" gutterBottom>
                {transfersEnabled
                  ? `${symbol} Transfers Disabled`
                  : `${symbol} Transfers Enabled`}
              </Typography>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleTransactonSuccess}
              >
                OK
              </StyledButton>
            </Fragment>
          )}
          {transactionStatus.value === 'error' && (
            <TransactionError
              errorMessage={transactionStatus.message}
              handleTransactionFail={handleTransactionFail}
            />
          )}
          {transactionStatus.value === '' && (
            <Fragment>
              <Typography variant="h6">
                Confirm {transfersEnabled ? 'disabling' : 'enabling'} transfers
                on MetaMask
              </Typography>
              <Typography variant="subtitle1">{exchangeAddress}</Typography>
            </Fragment>
          )}
        </Paper>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ transactionStatus }) => ({ transactionStatus });

ToggleTransfers.propTypes = {
  symbol: PropTypes.string.isRequired,
  transactionStatus: PropTypes.object.isRequired,
  toggleTransfersInStore: PropTypes.func.isRequired,
  setTransactionStatus: PropTypes.func.isRequired,
  resetTransactionStatus: PropTypes.func.isRequired,
  getCallbacks: PropTypes.func.isRequired,
  cash36Contract: PropTypes.object.isRequired,
  web3: PropTypes.object.isRequired
};

export default addCash36(
  connect(
    mapStateToProps,
    {
      toggleTransfersInStore,
      setTransactionStatus,
      resetTransactionStatus
    }
  )(withStyles(styles)(ToggleTransfers))
);
