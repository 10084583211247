import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../config/api';

import './BankBalance.scss';

const BankBalance = ({ symbol }) => {
  const _isMounted = useRef(true);
  const [bankBalance, setBankBalance] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  const fetchBalance = async () => {
    setFetching(true);
    try {
      const response = await api.get(`/exchange/admin/bank-balance/${symbol}`);
      if (_isMounted.current) {
        setBankBalance(response.data);
        setFetching(false);
      }
    } catch (err) {
      if (_isMounted.current) {
        const { error, message } = err.response.data;
        setError(`${error}: ${message}`);
        setFetching(false);
      }
    }
  };

  useEffect(() => {
    fetchBalance();

    return () => (_isMounted.current = false);
  }, []);

  return (
    <div className="admin-bank-balance">
      <span className="admin-bank-balance__title">Bank Account Balance</span>
      <span className="admin-bank-balance__value">
        {bankBalance
          ? `${bankBalance.balance} ${symbol.replace('36', '')}`
          : 'N/A'}
        {fetching && <CircularProgress size={20} />}
      </span>
      <span className="admin-bank-balance__update">
        Last Updated:{' '}
        {bankBalance && bankBalance.lastUpdated
          ? bankBalance.lastUpdated
          : 'N/A'}
      </span>
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

BankBalance.propTypes = {
  symbol: PropTypes.string
};

export default BankBalance;
