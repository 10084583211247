import React, { useState } from 'react';
import Web3 from 'web3';
import Web3Provider from '../../../components/Web3Provider';
import { Tabs, Tab } from '@material-ui/core';
import requireAuth from '../../../components/requireAuth';
import NetworkProvider from '../../../components/utils/NetworkProvider';
import { WEB3_NODE } from '../../../config/api';

import OrdersTable from '../OrdersTable';
import './UserOrders.scss';

const UserOrders = () => {
  const [value, setValue] = useState(0);

  const handleChange = (e, newVal) => setValue(newVal);

  return (
    <div className="wrapper user-orders">
      <div className="user-orders__tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          centered
        >
          <Tab label="BUY ORDERS" />
          <Tab label="SELL ORDERS" />          
        </Tabs>
      </div>
      <Web3Provider
        defaultProvider={cb =>
          cb(new Web3(new Web3.providers.HttpProvider(WEB3_NODE)))
        } // fallback if metamask is not detected
        loading="Loading..."
        error={error => {
          return (
            <>
              <p className="error-text align-center">{error.message}</p>
              <div
                className={`user-orders-panel ${value === 0 ? '--active' : ''}`}
              >
                <OrdersTable hideMint />
              </div>
              <div
                className={`user-orders-panel ${value === 1 ? '--active' : ''}`}
              >
                <OrdersTable isSell hideMint />
              </div>
            </>
          );
        }}
      >
        <NetworkProvider>
          <div className={`user-orders-panel ${value === 0 ? '--active' : ''}`}>
            <OrdersTable />
          </div>
          <div className={`user-orders-panel ${value === 1 ? '--active' : ''}`}>
            <OrdersTable isSell />
          </div>
        </NetworkProvider>
      </Web3Provider>
    </div>
  );
};

export default requireAuth(UserOrders);
