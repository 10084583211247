import api from '../config/api';
import { GET_USER_ORDERS } from './types';
const handleError = error => {
  let errorMessage =
    error.response.data.message &&
    error.response.data.message !== 'No message available'
      ? error.response.data.message
      : error.message;
  return Promise.reject(Error(errorMessage));
};

export const getUserOrders = () => async dispatch => {
  try {
    const response = await api.get('/compliance/admin/orders');

    console.log(response);

    dispatch({
      type: GET_USER_ORDERS,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
  }
};

export const getOrders = async (isSell, filters) => {
  try {
    let params = '';
    if (filters) {
      const keys = Object.keys(filters)
        .reduce((acc, key) => {
          if (filters[key]) {
            acc.push(`${key}=${filters[key]}`);
          }
          return acc;
        }, [])
        .join('&');

      params = `?${keys}`;
    }

    const type = isSell ? 'sell-orders' : 'buy-orders';
    const response = await await api.get(`/exchange/admin/${type}${params}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const resetOrder = async id => {
  try {
    const response = await await api.post(
      `/exchange/admin/buy-orders/reset/${id}`
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const showDetails = async id => {
  try {
    const response = await await api.get(
      `/exchange/admin/buy-orders/showDetails/${id}`
    );
    alert(response.data);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};


export const sendViaMail = async id => {
  try {
    const response = await await api.put(
      `/exchange/admin/buy-orders/sendViaMail/${id}`
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const addAsCorrect = async id => {
  try {
    const response = await await api.put(
      `/exchange/admin/buy-orders/addIbanToWhitelist/${id}`
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const releaseOrder = async id => {
  try {
    const response = await await api.post(
      `/exchange/admin/sell-orders/release/${id}`
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
