import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LoginForm from '../LoginForm';
import Logo from '../Logo';

import styles from './styles';

const LoginPage = ({ classes, history, isAuthenticated }) => {
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/administration');
    }
  });

  return (
    <div className={classes.root}>
      <div className={classes.leftPanel}>
        <div className={classes.header}>
          <Logo />
        </div>
        <div>
          <LoginForm />
        </div>
      </div>
      <div className={classes.rightPanel}>&nbsp;</div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

LoginPage.propTypes = {
  authed: PropTypes.bool,
  history: PropTypes.object,
  classes: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(styles)(LoginPage));
