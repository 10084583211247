export default theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.greys.headerGrey,
    maxWidth: '140rem',
    margin: '0 auto'
  },
  toolbar: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4rem 0 8rem 0',
    maxWidth: '120rem',
    width: '100%',
    margin: '0 auto'
  },
  menuBar: {
    display: 'flex',
    alignItems: 'center'
  },
  logoStyle: {
    height: '5rem',
    margin: '1rem'
  }
});
