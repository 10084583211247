import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';

import { getDocument, sendMrzData, scanMrzData } from '../../../actions/users';
import { getFullDate } from '../../../utils/mrz.utils';
import StyledButton from '../../../components/StyledButton';
import StatusIcon from '../../../components/StatusIcon';
import * as mrzValidator from '../MRZ/validateMrzData';
import AdditionalClarifications from '../AdditionalClarifications';
import DownloadDoc from '../DownloadDoc';

import './MRZ.scss';

const MRZ = ({ user, getDetails }) => {
  const {
    userDocuments,
    mrzResult,
    username,
    checklist,
    complianceDocuments
  } = user;
  const { mrzScanDone } = checklist;

  const docs = userDocuments.reduce((acc, { type, name, id }) => {
    acc[type] = { name, id };
    return acc;
  }, {});

  const [mrzDocumentType, setMrzDocumentType] = useState('');
  const [mrzScanData, setMrzData] = useState(null);
  const [mrzSaved, setMrzSaved] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [scanError, setScanError] = useState('');
  const [saveError, setSaveError] = useState('');

  const runMrzScan = async () => {
    if (!mrzDocumentType || isScanning) return;
    setScanError('');
    try {
      setIsScanning(true);
      const file = await getDocument(docs[mrzDocumentType].id);
      let formData = new FormData();
      formData.append('file', file);
      const mrzData = await scanMrzData(formData);

      const birthDateValid = mrzValidator.validateDateOfBirth(
        mrzData.birthDate,
        user.dateOfBirth
      );

      const expirationDateValid = mrzValidator.validateExpirationDate(
        mrzData.expirationDate
      );

      const firstNameValid = mrzValidator.validateName(
        mrzData.firstName,
        user.firstName
      );

      const lastNameValid = mrzValidator.validateName(
        mrzData.lastName,
        user.lastName
      );

      const nationalityValid = mrzValidator.validateNationality(
        mrzData.nationality,
        user.nationality.code
      );

      const birthDateCheckDigitValid = mrzValidator.checkDigitVerify(
        mrzData.birthDate,
        mrzData.birthDateCheckDigit
      );

      const expirationDateCheckDigitValid = mrzValidator.checkDigitVerify(
        mrzData.expirationDate,
        mrzData.expirationDateCheckDigit
      );

      const documentNumberCheckDigitValid = mrzValidator.checkDigitVerify(
        mrzData.documentNumber,
        mrzData.documentNumberCheckDigit
      );

      // const composite =
      //   mrzData.documentNumber +
      //   '<' +
      //   mrzData.documentNumberCheckDigit +
      //   mrzData.optional1 +
      //   mrzData.birthDate +
      //   mrzData.birthDateCheckDigit +
      //   mrzData.expirationDate +
      //   mrzData.expirationDateCheckDigit +
      //   mrzData.optional2;

      // const compositeCheckDigitValid = mrzValidator.checkDigitVerify(
      //   composite,
      //   mrzData.compositeCheckDigit
      // );

      const mrzValid =
        birthDateValid &&
        expirationDateValid &&
        firstNameValid &&
        lastNameValid &&
        nationalityValid &&
        birthDateCheckDigitValid &&
        expirationDateCheckDigitValid &&
        documentNumberCheckDigitValid;

      const parsedMrzData = {
        birthDate: mrzData.birthDate,
        birthDateValid,
        documentCode: mrzData.documentCode,
        documentNumber: mrzData.documentNumber,
        expirationDate: mrzData.expirationDate,
        expirationDateValid,
        firstName: mrzData.firstName,
        firstNameValid,
        issuingState: mrzData.issuingState,
        lastName: mrzData.lastName,
        lastNameValid,
        mrzValid,
        nationality: mrzData.nationality,
        nationalityValid,
        sex: mrzData.sex
      };

      setMrzData(parsedMrzData);
      setShowSaveButton(true);
    } catch (error) {
      setScanError(error);
      setIsScanning(false);
    }
    setIsScanning(false);
  };

  const mrzData = useMemo(() => {
    if (!mrzScanData && !mrzResult) return null;
    const data = { ...mrzResult, ...mrzScanData };
    const dob = getFullDate(data.birthDate);
    const docExpiration = getFullDate(data.expirationDate);

    data.birthDate = `${dob.day}.${dob.month}.${dob.year}`;
    data.expirationDate = `${docExpiration.day}.${docExpiration.month}.${docExpiration.year}`;

    return data;
  }, [mrzScanData, mrzResult]);

  const saveMrzResult = async () => {
    setIsSaving(true);
    setSaveError('');
    try {
      await sendMrzData(username, mrzScanData);
      await getDetails(username);
      setShowSaveButton(false);

      setTimeout(() => {
        setMrzSaved(false);
      }, 3000);
      setMrzSaved(true);
    } catch (error) {
      setIsSaving(false);
      setSaveError(error);
    }
    setIsSaving(false);
  };

  const userFiles = complianceDocuments.filter(
    doc => doc.type === 'MRZ_Manual'
  );

  return (
    <div className="mrz-container">
      {!mrzScanDone && (
        <Fragment>
          <h3>MRZ Scan</h3>
          <div className="mrz">
            <div>
              <span>Run scan for</span>
              <RadioGroup
                value={mrzDocumentType}
                onChange={event => {
                  setMrzDocumentType(event.target.value);
                }}
                className="mrz__radio-group"
              >
                <FormControlLabel
                  value="ID_Front"
                  control={<Radio color="primary" />}
                  labelPlacement="start"
                  label="ID Front"
                  disabled={!docs['ID_Front']}
                />
                <FormControlLabel
                  value="ID_Back"
                  control={<Radio color="primary" />}
                  labelPlacement="start"
                  label="ID Back"
                  disabled={!docs['ID_Back']}
                />
                <FormControlLabel
                  value="MRZ_Manual"
                  control={<Radio color="primary" />}
                  labelPlacement="start"
                  label="External"
                />
              </RadioGroup>
            </div>
            <div className="mrz__scan-button">
              <StyledButton
                disabled={
                  !mrzDocumentType ||
                  isScanning ||
                  mrzDocumentType === 'MRZ_Manual'
                }
                onClick={runMrzScan}
              >
                {isScanning ? <CircularProgress size={20} /> : 'Run'}
              </StyledButton>
              <span className="error-text">{scanError}</span>
            </div>
          </div>
          <h3>Result</h3>
        </Fragment>
      )}
      {mrzData ? (
        <div className="mrz-data">
          <div className="mrz-data__item">
            <span>Document Type</span>
            <span>{mrzData.documentCode}</span>
          </div>
          <div className="mrz-data__item">
            <span>Document Number</span>
            <span>{mrzData.documentNumber}</span>
          </div>
          <div className="mrz-data__item">
            <span>Expiry Date</span>
            <span>
              {mrzData.expirationDate}
              {mrzData.expirationDateValid ? (
                <StatusIcon valid />
              ) : (
                <StatusIcon />
              )}
            </span>
          </div>
          <div className="mrz-data__item">
            <span>First Name</span>
            <span className="capitalize">
              {mrzData.firstName.toLowerCase()}
              {mrzData.firstNameValid ? <StatusIcon valid /> : <StatusIcon />}
            </span>
          </div>
          <div className="mrz-data__item">
            <span>Last Name</span>
            <span className="capitalize">
              {mrzData.lastName.toLowerCase()}
              {mrzData.lastNameValid ? <StatusIcon valid /> : <StatusIcon />}
            </span>
          </div>
          <div className="mrz-data__item">
            <span>Date of Birth</span>
            <span>
              {mrzData.birthDate}
              {mrzData.birthDateValid ? <StatusIcon valid /> : <StatusIcon />}
            </span>
          </div>
          <div className="mrz-data__item">
            <span>Sex</span>
            <span>{mrzData.sex}</span>
          </div>
          <div className="mrz-data__item">
            <span>Nationality</span>
            <span>
              {mrzData.nationality}{' '}
              {mrzData.nationalityValid ? <StatusIcon valid /> : <StatusIcon />}
            </span>
          </div>
          <div className="mrz-data__item mrz-data__save-button">
            {showSaveButton && (
              <Fragment>
                <StyledButton
                  disabled={isSaving}
                  style={isSaving ? { opacity: '0.5' } : null}
                  onClick={saveMrzResult}
                >
                  {isSaving ? <CircularProgress size={20} /> : 'Save Result'}
                </StyledButton>
                <span className="error-text">{saveError}</span>
              </Fragment>
            )}
            {mrzSaved && <div className="mrz-data__item--saved">Saved</div>}
          </div>
        </div>
      ) : (
        <div className="mrz-data__no-scan-run">No Scan run yet</div>
      )}
      {mrzDocumentType === 'MRZ_Manual' && (
        <AdditionalClarifications
          username={username}
          getDetails={getDetails}
          docType="MRZ_Manual"
          title="MRZ verified in external tool"
        />
      )}
      {userFiles.length > 0 && (
        <div className="mrz__download-external-documents">
          <h3>External Mrz Verification Documents</h3>
          {userFiles.map(file => (
            <DownloadDoc id={file.id} name={file.name} key={file.id} showName />
          ))}
        </div>
      )}
    </div>
  );
};

MRZ.propTypes = {
  getDetails: PropTypes.func,
  user: PropTypes.object
};

export default MRZ;
