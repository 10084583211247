
import backgroundImage from '../../assets/background-login.jpg';

export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    backgroundColor: theme.palette.greys.headerGrey
  },
  leftPanel: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh'
  },
  rightPanel: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '5rem',
    marginBottom: '3rem'
  },
  bigLogoBox: {
    backgroundImage: theme.gradients.primary,
    borderRadius: 10,
    width: '24rem',
    height: '30rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem 2.5rem'
  },
  logoBoxUpper: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '7rem',
    fontWeight: '500',
    color: theme.palette.common.white
  },
  logoBoxLower: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '4rem',
    color: theme.palette.common.white
  }
});
