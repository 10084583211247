import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Web3 from 'web3';
import Web3Provider from '../../components/Web3Provider';

import { WEB3_NODE } from '../../config/api';
import NetworkProvider from '../../components/utils/NetworkProvider';
import { getMetamaskAccount } from '../../utils/metamask.utils';
import TokenList from './TokenList';
import GetTokens from '../../components/utils/GetTokens';
import requireAuth from '../../components/requireAuth';
import Loader from '../../components/Loader';
import NodeDetails from './NodeDetails';
import Company from './Company';
import { rolesObj } from '../../utils/roles.const';

import './Administration.scss';

const networkObj = {
  '1': 'MAINNET',
  '4': 'RINKEBY',
  '85458545': 'LOCAL',
  '3': 'ROPSTEN',
  '5': 'GOERLI',
};

const Administration = ({ roles, tokensNotLoaded, tokensError }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [network, setNetwork] = useState(null);
  const [mmNetwork, setMmNetwork] = useState(null);
  const isAdmin = useMemo(() => {
    if (!roles) return false;
    return roles.includes(rolesObj.admin);
  }, [roles]);

  const checkForMetamask = async () => {
    try {
      const address = await getMetamaskAccount();
      if (address) {
        setIsEnabled(true);
        setMmNetwork(window.ethereum.networkVersion);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    checkForMetamask();
  }, []);

  return (
    <div className="wrapper">
      <Web3Provider
        defaultProvider={cb =>
          cb(new Web3(new Web3.providers.HttpProvider(WEB3_NODE)))
        } // fallback if metamask is not detected
        loading="Loading..."
        error={error => {
          return (
            <>
              <NodeDetails setNetwork={setNetwork} />
              {isAdmin && <Company />}
              <p className="administration__error">{error.message}</p>
            </>
          );
        }}
      >
        <NetworkProvider>
          <NodeDetails setNetwork={setNetwork} />
          {isAdmin && <Company />}
          {(error || tokensError) && (
            <p className="administration__error">{error || tokensError}</p>
          )}
          {network && mmNetwork && network !== mmNetwork && (
            <p className="administration__error">
              Wrong MetaMask network ({networkObj[mmNetwork]}), please connect
              to {networkObj[network]} network.
            </p>
          )}
          {tokensNotLoaded && (
            <>
              {!tokensError && <Loader />}
              <GetTokens setNetwork={setNetwork} />
            </>
          )}
          {!tokensNotLoaded &&
            network &&
            mmNetwork &&
            network === mmNetwork && <TokenList disableDropdown={!isEnabled} />}
        </NetworkProvider>
      </Web3Provider>
    </div>
  );
};

const mapStateToProps = ({
  auth: { roles },
  tokens: { tokens, tokensError }
}) => ({
  tokensNotLoaded: !tokens.length,
  tokensError,
  roles
});

Administration.propTypes = {
  tokensNotLoaded: PropTypes.bool
};

export default requireAuth(connect(mapStateToProps)(Administration));
