import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AddExchange from '../AddExchange';
import RemoveExchange from '../RemoveExchange';
import ExchangeBalance from '../ExchangeBalance';
import styles from './styles';

const AllowedExchanges = ({ allowedExchanges, classes, tokenAddress }) => {
  const listExchanges = exchanges => {
    return exchanges.map((exchange, index) => {
      const { exchangeAddress } = exchange;
      return (
        <Fragment key={`${exchange}${index}`}>
          <Grid item xs={10} className={classes.exchange}>
            <Typography noWrap style={{ fontSize: '1.6rem' }}>
              {exchangeAddress}
            </Typography>
            <Typography color="textSecondary" className={classes.balance}>
              Balance:
              <ExchangeBalance
                exchangeAddress={exchangeAddress}
                tokenAddress={tokenAddress}
              />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <RemoveExchange
              exchangeAddress={exchangeAddress}
              tokenAddress={tokenAddress}
              className={classes.removeButton}
            />
          </Grid>
        </Fragment>
      );
    });
  };
  return (
    <Grid container alignItems="center">
      <Grid container alignItems="center" className={classes.root}>
        <Grid item xs={10}>
          <Typography noWrap variant="subtitle1" color="textSecondary">
            Allowed Exchanges
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <AddExchange tokenAddress={tokenAddress} />
        </Grid>
      </Grid>

      <Grid container className={classes.root}>
        {listExchanges(allowedExchanges)}
      </Grid>
    </Grid>
  );
};

AllowedExchanges.propTypes = {
  allowedExchanges: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object,
  tokenAddress: PropTypes.string.isRequired
};

export default withStyles(styles)(AllowedExchanges);
