export default {
  paper: {
    minWidth: '30rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  button: {
    padding: 0
  }
};
