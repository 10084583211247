import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import StyledButton from '../../../components/StyledButton';

const TransactionError = ({ errorMessage, handleTransactionFail }) => (
  <div>
    <Typography variant="h6" gutterBottom>
      Error!
    </Typography>
    <Typography gutterBottom>{errorMessage}</Typography>
    <StyledButton
      variant="contained"
      color="secondary"
      onClick={handleTransactionFail}
      fullWidth
    >
      OK
    </StyledButton>
  </div>
);

TransactionError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  handleTransactionFail: PropTypes.func.isRequired
};

export default TransactionError;
