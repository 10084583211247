import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const TransactionSuccess = ({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h6" gutterBottom>
      Transaction sent
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      Mining...
    </Typography>
    <CircularProgress size={70} color="primary" />
  </div>
);

TransactionSuccess.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(TransactionSuccess);
