import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StyledButton from '../StyledButton';
import { login } from '../../actions/auth';

import styles from './styles';

const LoginForm = ({ history, login, classes, errorMessage }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubtmitting] = useState(false);

  const handleFormSubmit = async event => {
    event.preventDefault();
    setSubtmitting(true);
    try {
      await login(username, password, () => {
        history.push('/administration');
      });
    } catch (error) {
      setError(error);
      setSubtmitting(false);
    }
  };

  return (
    <form className={classes.root} onSubmit={handleFormSubmit}>
      <Typography variant="h4" color="textPrimary" className={classes.headline}>
        Welcome
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Welcome to the{' '}
        <span style={{ fontWeight: '500' }}>element36 Admin Panel</span>
      </Typography>
      <Typography
        variant="subtitle1"
        color="textPrimary"
        className={classes.marginBot}
      >
        Please, enter your username and password
      </Typography>
      <TextField
        name="username"
        label="Username"
        type="text"
        autoComplete="off"
        maring="normal"
        value={username}
        onChange={event => {
          setUsername(event.target.value);
        }}
        fullWidth
        className={classes.textField}
        InputProps={{
          disableUnderline: true,
          className: classes.input
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.label
        }}
      />
      <TextField
        name="password"
        label="Password"
        type="password"
        autoComplete="off"
        maring="normal"
        value={password}
        onChange={event => {
          setPassword(event.target.value);
        }}
        fullWidth
        className={classes.textField}
        InputProps={{
          disableUnderline: true,
          className: classes.input
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.label
        }}
        style={{
          borderTop: 'none'
        }}
      />
      <Typography className={classes.errorMessage}>
        {errorMessage || error}
      </Typography>
      <StyledButton
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        fullWidth
        className={classes.button}
        disabled={submitting}
      >
        <span>Log in</span>
        <ArrowForwardIcon />
      </StyledButton>
      <Typography
        className={classes.forgotPassword}
        color="textSecondary"
        gutterBottom
      >
        Forgot password?
      </Typography>
    </form>
  );
};

const mapStateToProps = state => ({ errorMessage: state.auth.errorMessage });

LoginForm.propTypes = {
  classes: PropTypes.object,
  errorMessage: PropTypes.string,
  history: PropTypes.object,
  login: PropTypes.func
};

export default connect(
  mapStateToProps,
  { login }
)(withStyles(styles)(withRouter(LoginForm)));
