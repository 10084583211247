export const mrzCountries = {};
mrzCountries['AFG'] = 'Afghanistan';
mrzCountries['ALB'] = 'Albania';
mrzCountries['DZA'] = 'Algeria';
mrzCountries['ASM'] = 'American Samoa';
mrzCountries['AND'] = 'Andorra';
mrzCountries['AGO'] = 'Angola';
mrzCountries['AIA'] = 'Anguilla';
mrzCountries['ATA'] = 'Antarctica';
mrzCountries['ATG'] = 'Antigua and Barbuda';
mrzCountries['ARG'] = 'Argentina';
mrzCountries['ARM'] = 'Armenia';
mrzCountries['ABW'] = 'Aruba';
mrzCountries['AUS'] = 'Australia';
mrzCountries['AUT'] = 'Austria';
mrzCountries['AZE'] = 'Azerbaijan';
mrzCountries['BHS'] = 'Bahamas';
mrzCountries['BHR'] = 'Bahrain';
mrzCountries['BGD'] = 'Bangladesh';
mrzCountries['BRB'] = 'Barbados';
mrzCountries['BLR'] = 'Belarus';
mrzCountries['BEL'] = 'Belgium';
mrzCountries['BLZ'] = 'Belize';
mrzCountries['BEN'] = 'Benin';
mrzCountries['BMU'] = 'Bermuda';
mrzCountries['BTN'] = 'Bhutan';
mrzCountries['BOL'] = 'Bolivia';
mrzCountries['BIH'] = 'Bosnia and Herzegovina';
mrzCountries['BWA'] = 'Botswana';
mrzCountries['BVT'] = 'Bouvet Island';
mrzCountries['BRA'] = 'Brazil';
mrzCountries['IOT'] = 'British Indian Ocean Territory';
mrzCountries['BRN'] = 'Brunei Darussalam';
mrzCountries['BGR'] = 'Bulgaria';
mrzCountries['BFA'] = 'Burkina Faso';
mrzCountries['BDI'] = 'Burundi';
mrzCountries['KHM'] = 'Cambodia';
mrzCountries['CMR'] = 'Cameroon';
mrzCountries['CAN'] = 'Canada';
mrzCountries['CPV'] = 'Cape Verde';
mrzCountries['CYM'] = 'Cayman Islands';
mrzCountries['CAF'] = 'Central African Republic';
mrzCountries['TCD'] = 'Chad';
mrzCountries['CHL'] = 'Chile';
mrzCountries['CHN'] = 'China';
mrzCountries['CXR'] = 'Christmas Island';
mrzCountries['CCK'] = 'Cocos (Keeling) Islands';
mrzCountries['COL'] = 'Colombia';
mrzCountries['COM'] = 'Comoros';
mrzCountries['COG'] = 'Congo';
mrzCountries['COK'] = 'Cook Islands';
mrzCountries['CRI'] = 'Costa Rica';
mrzCountries['CIV'] = "Côte d'Ivoire";
mrzCountries['HRV'] = 'Croatia';
mrzCountries['CUB'] = 'Cuba';
mrzCountries['CYP'] = 'Cyprus';
mrzCountries['CZE'] = 'Czech Republic';
mrzCountries['PRK'] = "Democratic People's Republic of Korea";
mrzCountries['COD'] = 'Democratic Republic of the Congo';
mrzCountries['DNK'] = 'Denmark';
mrzCountries['DJI'] = 'Djibouti';
mrzCountries['DMA'] = 'Dominica';
mrzCountries['DOM'] = 'Dominican Republic';
mrzCountries['TMP'] = 'East Timor';
mrzCountries['ECU'] = 'Ecuador';
mrzCountries['EGY'] = 'Egypt';
mrzCountries['SLV'] = 'El Salvador';
mrzCountries['GNQ'] = 'Equatorial Guinea';
mrzCountries['ERI'] = 'Eritrea';
mrzCountries['EST'] = 'Estonia';
mrzCountries['ETH'] = 'Ethiopia';
mrzCountries['FLK'] = 'Falkland Islands (Malvinas)';
mrzCountries['FRO'] = 'Faeroe Islands';
mrzCountries['FJI'] = 'Fiji';
mrzCountries['FIN'] = 'Finland';
mrzCountries['FRA'] = 'France';
mrzCountries['FXX'] = 'France, Metropolitan';
mrzCountries['GUF'] = 'French Guiana';
mrzCountries['PYF'] = 'French Polynesia';
mrzCountries['GAB'] = 'Gabon';
mrzCountries['GMB'] = 'Gambia';
mrzCountries['GEO'] = 'Georgia';
mrzCountries['D'] = 'Germany';
mrzCountries['GHA'] = 'Ghana';
mrzCountries['GIB'] = 'Gibraltar';
mrzCountries['GRC'] = 'Greece';
mrzCountries['GRL'] = 'Greenland';
mrzCountries['GRD'] = 'Grenada';
mrzCountries['GLP'] = 'Guadeloupe';
mrzCountries['GUM'] = 'Guam';
mrzCountries['GTM'] = 'Guatemala';
mrzCountries['GIN'] = 'Guinea';
mrzCountries['GNB'] = 'Guinea-Bissau';
mrzCountries['GUY'] = 'Guyana';
mrzCountries['HTI'] = 'Haiti';
mrzCountries['HMD'] = 'Heard and McDonald Islands';
mrzCountries['VAT'] = 'Holy See (Vatican City State)';
mrzCountries['HND'] = 'Honduras';
mrzCountries['HKG'] = 'Hong Kong';
mrzCountries['HUN'] = 'Hungary';
mrzCountries['ISL'] = 'Iceland';
mrzCountries['IND'] = 'India';
mrzCountries['IDN'] = 'Indonesia';
mrzCountries['IRN'] = 'Iran, Islamic Republic of';
mrzCountries['IRQ'] = 'Iraq';
mrzCountries['IRL'] = 'Ireland';
mrzCountries['ISR'] = 'Israel';
mrzCountries['ITA'] = 'Italy';
mrzCountries['JAM'] = 'Jamaica';
mrzCountries['JPN'] = 'Japan';
mrzCountries['JOR'] = 'Jordan';
mrzCountries['KAZ'] = 'Kazakhstan';
mrzCountries['KEN'] = 'Kenya';
mrzCountries['KIR'] = 'Kiribati';
mrzCountries['KWT'] = 'Kuwait';
mrzCountries['KGZ'] = 'Kyrgyzstan';
mrzCountries['LAO'] = "Lao People's Democratic Republic";
mrzCountries['LVA'] = 'Latvia';
mrzCountries['LBN'] = 'Lebanon';
mrzCountries['LSO'] = 'Lesotho';
mrzCountries['LBR'] = 'Liberia';
mrzCountries['LBY'] = 'Libyan Arab Jamahiriya';
mrzCountries['LIE'] = 'Liechtenstein';
mrzCountries['LTU'] = 'Lithuania';
mrzCountries['LUX'] = 'Luxembourg';
mrzCountries['MDG'] = 'Madagascar';
mrzCountries['MWI'] = 'Malawi';
mrzCountries['MYS'] = 'Malaysia';
mrzCountries['MDV'] = 'Maldives';
mrzCountries['MLI'] = 'Mali';
mrzCountries['MLT'] = 'Malta';
mrzCountries['MHL'] = 'Marshall Islands';
mrzCountries['MTQ'] = 'Martinique';
mrzCountries['MRT'] = 'Mauritania';
mrzCountries['MUS'] = 'Mauritius';
mrzCountries['MYT'] = 'Mayotte';
mrzCountries['MEX'] = 'Mexico';
mrzCountries['FSM'] = 'Micronesia, Federated States of';
mrzCountries['MCO'] = 'Monaco';
mrzCountries['MNG'] = 'Mongolia';
mrzCountries['MNE'] = 'Montenegro';
mrzCountries['MSR'] = 'Montserrat';
mrzCountries['MAR'] = 'Morocco';
mrzCountries['MOZ'] = 'Mozambique';
mrzCountries['MMR'] = 'Myanmar';
mrzCountries['NAM'] = 'Namibia';
mrzCountries['NRU'] = 'Nauru';
mrzCountries['NPL'] = 'Nepal';
mrzCountries['NLD'] = 'Netherlands, Kingdom of the';
mrzCountries['ANT'] = 'Netherlands Antilles';
mrzCountries['NTZ'] = 'Neutral Zone';
mrzCountries['NCL'] = 'New Caledonia';
mrzCountries['NZL'] = 'New Zealand';
mrzCountries['NIC'] = 'Nicaragua';
mrzCountries['NER'] = 'Niger';
mrzCountries['NGA'] = 'Nigeria';
mrzCountries['NIU'] = 'Niue';
mrzCountries['NFK'] = 'Norfolk Island';
mrzCountries['MNP'] = 'Northern Mariana Islands';
mrzCountries['NOR'] = 'Norway';
mrzCountries['OMN'] = 'Oman';
mrzCountries['PAK'] = 'Pakistan';
mrzCountries['PLW'] = 'Palau';
mrzCountries['PSE'] = 'Palestine';
mrzCountries['PAN'] = 'Panama';
mrzCountries['PNG'] = 'Papua New Guinea';
mrzCountries['PRY'] = 'Paraguay';
mrzCountries['PER'] = 'Peru';
mrzCountries['PHL'] = 'Philippines';
mrzCountries['PCN'] = 'Pitcairn';
mrzCountries['POL'] = 'Poland';
mrzCountries['PRT'] = 'Portugal';
mrzCountries['PRI'] = 'Puerto Rico';
mrzCountries['QAT'] = 'Qatar';
mrzCountries['KOR'] = 'Republic of Korea';
mrzCountries['MDA'] = 'Republic of Moldova';
mrzCountries['REU'] = 'Réunion';
mrzCountries['ROU'] = 'Romania';
mrzCountries['RUS'] = 'Russian Federation';
mrzCountries['RWA'] = 'Rwanda';
mrzCountries['SHN'] = 'Saint Helena';
mrzCountries['KNA'] = 'Saint Kitts and Nevis';
mrzCountries['LCA'] = 'Saint Lucia';
mrzCountries['SPM'] = 'Saint Pierre and Miquelon';
mrzCountries['VCT'] = 'Saint Vincent and the Grenadines';
mrzCountries['WSM'] = 'Samoa';
mrzCountries['SMR'] = 'San Marino';
mrzCountries['STP'] = 'Sao Tome and Principe';
mrzCountries['SAU'] = 'Saudi Arabia';
mrzCountries['SRB'] = 'Serbia';
mrzCountries['SEN'] = 'Senegal';
mrzCountries['SYC'] = 'Seychelles';
mrzCountries['SLE'] = 'Sierra Leone';
mrzCountries['SGP'] = 'Singapore';
mrzCountries['SVK'] = 'Slovakia';
mrzCountries['SVN'] = 'Slovenia';
mrzCountries['SLB'] = 'Solomon Islands';
mrzCountries['SOM'] = 'Somalia';
mrzCountries['ZAF'] = 'South Africa';
mrzCountries['SGS'] = 'South Georgia and the South Sandwich Island';
mrzCountries['SSD'] = 'South Sudan';
mrzCountries['ESP'] = 'Spain';
mrzCountries['LKA'] = 'Sri Lanka';
mrzCountries['SDN'] = 'Sudan';
mrzCountries['SUR'] = 'Suriname';
mrzCountries['SJM'] = 'Svalbard and Jan Mayen Islands';
mrzCountries['SWZ'] = 'Swaziland';
mrzCountries['SWE'] = 'Sweden';
mrzCountries['CHE'] = 'Switzerland';
mrzCountries['SYR'] = 'Syrian Arab Republic';
mrzCountries['TWN'] = 'Taiwan Province of China';
mrzCountries['TJK'] = 'Tajikistan';
mrzCountries['TLS'] = 'Timor Leste';
mrzCountries['THA'] = 'Thailand';
mrzCountries['MKD'] = 'The former Yugoslav Republic of Macedonia';
mrzCountries['TGO'] = 'Togo';
mrzCountries['TKL'] = 'Tokelau';
mrzCountries['TON'] = 'Tonga';
mrzCountries['TTO'] = 'Trinidad and Tobago';
mrzCountries['TUN'] = 'Tunisia';
mrzCountries['TUR'] = 'Turkey';
mrzCountries['TKM'] = 'Turkmenistan';
mrzCountries['TCA'] = 'Turks and Caicos Islands';
mrzCountries['TUV'] = 'Tuvalu';
mrzCountries['UGA'] = 'Uganda';
mrzCountries['UKR'] = 'Ukraine';
mrzCountries['ARE'] = 'United Arab Emirates';
mrzCountries['GBR'] =
  'United Kingdom of Great Britain and Northern Ireland Citizen';
mrzCountries['GBD'] =
  'United Kingdom of Great Britain and Northern Ireland Dependent Territories Citizen';
mrzCountries['GBN'] =
  'United Kingdom of Great Britain and Northern Ireland National (oversees)';
mrzCountries['GBO'] =
  'United Kingdom of Great Britain and Northern Ireland Oversees Citizen';
mrzCountries['GBP'] =
  'United Kingdom of Great Britain and Northern Ireland Protected Person';
mrzCountries['GBS'] =
  'United Kingdom of Great Britain and Northern Ireland Subject';
mrzCountries['TZA'] = 'United Republic of Tanzania';
mrzCountries['USA'] = 'United States of America';
mrzCountries['UMI'] = 'United States of America Minor Outlying Islands';
mrzCountries['URY'] = 'Uruguay';
mrzCountries['UZB'] = 'Uzbekistan';
mrzCountries['VUT'] = 'Vanuatu';
mrzCountries['VEN'] = 'Venezuela';
mrzCountries['VNM'] = 'Viet Nam';
mrzCountries['VGB'] = 'Virgin Islands (Great Britian)';
mrzCountries['VIR'] = 'Virgin Islands (United States)';
mrzCountries['WLF'] = 'Wallis and Futuna Islands';
mrzCountries['ESH'] = 'Western Sahara';
mrzCountries['YEM'] = 'Yemen';
mrzCountries['ZAR'] = 'Zaire';
mrzCountries['ZMB'] = 'Zambia';
mrzCountries['ZWE'] = 'Zimbabwe';
mrzCountries['UNO'] = 'United Nations Organization Official';
mrzCountries['UNA'] = 'United Nations Organization Specialized Agency Official';
mrzCountries['XAA'] = 'Stateless (per Article 1 of 1954 convention)';
mrzCountries['XXB'] =
  'Refugee (per Article 1 of 1951 convention, amended by 1967 protocol)';
mrzCountries['XXC'] = 'Refugee (non-convention)';
mrzCountries['XXX'] = 'Unspecified / Unknown';
