import React from 'react';
import PropTypes from 'prop-types';
import Date from '../Date';
import Amount from '../Amount';

import './Payment.scss';

const Payment = ({ amount, symbol, date }) => {
  return (
    <div className="user-orders__payment">
      <Date date={date} />
      <Amount amount={amount} symbol={symbol} />
    </div>
  );
};

Payment.propTypes = {
  amount: PropTypes.number,
  symbol: PropTypes.string,
  date: PropTypes.string
};

export default Payment;
