import React from 'react';
import PropTypes from 'prop-types';
import ToggleTransfers from '../ToggleTransfers';
import UpdateComplianceAddress from '../UpdateComplianceAddress';
import UpdateControllerAddress from '../UpdateControllerAddress';
import UpdateTokenCap from '../UpdateTokenCap';
import ComplianceBalance from '../ComplianceBalance';
import AllowedExchanges from '../AllowedExchanges';
import BankBalance from '../BankBalance';

import './TokenPanel.scss';

const TokenPanel = ({
  transfersEnabled,
  controllerAddress,
  complianceAddress,
  allowedExchanges,
  address,
  symbol,
  cap,
  decimals
}) => {
  return (
    <div className={`token-panel token-panel--${symbol}`}>
      <div className="token-panel__left">
        <div className="token-panel__transfers">
          <div>Transfers Enabled</div>
          <ToggleTransfers
            transfersEnabled={transfersEnabled}
            symbol={symbol}
          />
        </div>

        <div className="token-panel__edit-field">
          <div>
            <div>Token Cap</div>
            <div>
              {cap} {symbol}
            </div>
          </div>

          <UpdateTokenCap cap={cap} symbol={symbol} decimals={decimals} />
        </div>

        <div className="token-panel__edit-field">
          <div>
            <div>Controller Address</div>
            <div>{controllerAddress}</div>
          </div>

          <UpdateControllerAddress
            controllerAddress={controllerAddress}
            symbol={symbol}
          />
        </div>

        <div className="token-panel__edit-field">
          <div>
            <div>Compliance Address</div>
            <div>{complianceAddress}</div>
          </div>
          <UpdateComplianceAddress
            complianceAddress={complianceAddress}
            symbol={symbol}
          />
        </div>

        <ComplianceBalance tokenAddress={address} />
      </div>
      <div className="token-panel__right">
        <AllowedExchanges
          allowedExchanges={allowedExchanges}
          tokenAddress={address}
        />
        <BankBalance symbol={symbol} />
      </div>
    </div>
  );
};

TokenPanel.propTypes = {
  address: PropTypes.string,
  transfersEnabled: PropTypes.bool,
  controllerAddress: PropTypes.string,
  complianceAddress: PropTypes.string,
  allowedExchanges: PropTypes.arrayOf(PropTypes.object),
  symbol: PropTypes.string,
  decimals: PropTypes.string
};

export default TokenPanel;
