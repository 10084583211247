import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const NoUserOrders = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.content}>
      <span className={classes.text}>There are no user orders</span>
    </div>
  </div>
);

NoUserOrders.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(NoUserOrders);
