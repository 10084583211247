import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from '../../../config/api';
import EthereumIcon from '../../../assets/ethereum-icon.svg';

import './NodeDetails.scss';

const NodeDetails = ({ setNetwork }) => {
  const [fetching, setFetching] = useState(true);
  const [gethInfo, setGethInfo] = useState(null);
  const [error, setError] = useState(null);

  const getGethInfo = async () => {
    setFetching(true);
    try {
      const response = await api.get('compliance/admin/geth');
      setGethInfo(response.data);
      setNetwork(response.data.network);
      setError(null);
    } catch (err) {
      const { error, message } = err.response.data;
      setError(`${error}: ${message}`);
    }
    setFetching(false);
  };

  useEffect(() => {
    getGethInfo();
  }, []);

  return (
    <div
      className={`node-details ${fetching ? 'node-details__loading' : ''}  ${
        error ? 'node-details__offline --error' : ''
      } ${
        !fetching && (gethInfo && gethInfo.online)
          ? 'node-details__online'
          : 'node-details__offline'
      } paper`}
    >
      <div>
        <img className="node-details__image" src={EthereumIcon} alt="" />
        <div className="node-details__name">
          {error && <div>{error}</div>}
          {gethInfo && (
            <>
              <div>{gethInfo.name}</div>
              <div>{gethInfo.clientVersion}</div>
            </>
          )}
        </div>
      </div>
      {gethInfo && gethInfo.online && (
        <>
          <div className="node-details__field">
            <div>Network</div>
            <div>{gethInfo.network}</div>
          </div>
          <div className="node-details__field">
            <div>Mining</div>
            <div>{gethInfo.mining ? 'Yes' : 'No'}</div>
          </div>
          <div className="node-details__field">
            <div>Pending Transactions</div>
            <div>{gethInfo.pendingTransactions}</div>
          </div>
          <div className="node-details__field">
            <div>Last Block</div>
            <div
              style={{
                color: gethInfo.syncing ? '#FF0000' : '#3BFF00'
              }}
            >
              {gethInfo.lastBlock}
            </div>
          </div>
        </>
      )}

      {gethInfo && !gethInfo.online && (
        <div className="node-details__offline-text">
          {gethInfo.name} seems to be offline! Please check.
        </div>
      )}
    </div>
  );
};

NodeDetails.propTypes = {
  setNetwork: PropTypes.func
};

export default NodeDetails;
