import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import './StyledButton.scss';

const StyledButton = props => (
  <Button
    {...props}
    // className={`${props.disabled ? 'styled-button-disabled' : null}`}
    style={{
      ...props.style,
      fontFamily: '"Rubik", sans-serif',
      opacity: props.disabled ? '0.5' : '1',
      color: props.disabled && 'hsla(212, 96%, 9%, 0.5)'
    }}
  >
    {props.children}
  </Button>
);

StyledButton.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any
};

export default StyledButton;
