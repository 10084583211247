import React from 'react';
import PropTypes from 'prop-types';
import CheckCircle from '@material-ui/icons/CheckCircle';
import AddCircle from '@material-ui/icons/AddCircle';

import './StatusIcon.scss';

const StatusIcon = ({ valid = false }) => {
  if (valid) return <CheckCircle className="status-icon status-icon__valid" />;

  return <AddCircle className="status-icon status-icon__invalid" />;
};

StatusIcon.propTypes = {
  valid: PropTypes.bool
};

export default StatusIcon;
