import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Nav = ({ classes }) => {
  return (
    <Tabs value={false} className={classes.root}>
      <Tab
        component={NavLink}
        to="/administration"
        label="Administration"
        activeClassName={classes.active}
        disableRipple
        className={classes.tab}
      />
      <Tab
        component={NavLink}
        to="/compliance"
        label="Compliance"
        activeClassName={classes.active}
        disableRipple
        className={classes.tab}
      />
      <Tab
        component={NavLink}
        to="/user-orders"
        label="User Orders"
        activeClassName={classes.active}
        disableRipple
        className={classes.tab}
      />
    </Tabs>
  );
};

Nav.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Nav);
