import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from '../../../components/StyledButton';

const ConfirmBox = ({ deny, identityAction, handleDialogClose, error }) => (
  <>
    <div className="compliance__action-buttons__confirm-box__message">
      Are you sure you want to {deny ? 'DENY identity' : 'CONFIRM identity'}?
    </div>
    <div className="compliance__action-buttons__confirm-box__buttons">
      <StyledButton onClick={identityAction}>Yes, I'm sure</StyledButton>
      <StyledButton onClick={handleDialogClose}>No</StyledButton>
    </div>
    <div className="error-text">{error}</div>
  </>
);

ConfirmBox.propTypes = {
  deny: PropTypes.bool,
  identityAction: PropTypes.func,
  handleDialogClose: PropTypes.func,
  error: PropTypes.string
};

export default ConfirmBox;
