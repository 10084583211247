import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Dropdown = ({ title, onClick, isActive, disableDropdown }) => {
  return (
    <div
      className={`dropdown__header ${isActive ? '--active' : ''}`}
      onClick={onClick}
    >
      <span>{title}</span>
      <span>{!disableDropdown && <ArrowDownIcon />}</span>
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.any,
  onClick: PropTypes.func,
  isActive: PropTypes.number,
  disableDropdown: PropTypes.bool
};

export default Dropdown;
