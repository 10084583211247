import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withWeb3 } from '../Web3Provider';
import { connect } from 'react-redux';
import { getTokens } from '../../actions/tokens';
import { getMetamaskAccount } from '../../utils/metamask.utils';

import addCash36 from '../../Cash36Provider';

const GetTokens = ({
  web3,
  getTokens,
  cash36Contract,
  cash36ExchangesContract,
  getTokenInstance,
  networkError
}) => {
  const onMount = async () => {
    try {
      await getTokens(loadTokensFromWeb3);
    } catch (error) {}
  };
  useEffect(() => {
    onMount();
  }, []);

  const loadTokensFromWeb3 = async () => {
    const address = await getMetamaskAccount();

    if (networkError) throw Error(networkError);

    try {
      const tokenAddresses = await cash36Contract.methods
        .getTokens()
        .call({ from: address });

      const tokens = tokenAddresses.map(async tokenAddress => {
        const token = getTokenInstance(tokenAddress);

        const controllerAddress = await token.methods
          .controller()
          .call({ from: address });
        const allExchanges = await cash36ExchangesContract.methods
          .getAllExchanges(tokenAddress)
          .call({ from: address });

        const allExchangesKeys = await Promise.all(
          allExchanges.map(async exchange => {
            return cash36ExchangesContract.methods
              .isAllowedExchange(exchange, tokenAddress)
              .call({ from: address });
          })
        );

        const allowedExchanges = allExchanges.filter(
          (exchange, index) => allExchangesKeys[index] === true
        );
        const symbol = await token.methods.symbol().call({ from: address });
        let cap = await token.methods.cap().call({ from: address });

        const complianceAddress = await cash36Contract.methods
          .getCompliance(symbol)
          .call({ from: address });

        const decimals = await token.methods.decimals().call({ from: address });

        return {
          address: tokenAddress,
          symbol,
          transfersEnabled: !(await token.methods
            .paused()
            .call({ from: address })),
          totalSupply: await token.methods
            .totalSupply()
            .call({ from: address }),
          controllerAddress,
          complianceAddress,
          name: await token.methods.name().call({ from: address }),
          decimals,
          cap: web3.utils.fromWei(cap),
          allowedExchanges: await Promise.all(
            allowedExchanges.map(async exchangeAddress => {
              let balance = await web3.eth.getBalance(exchangeAddress);
              balance = await web3.utils.fromWei(balance, 'ether');
              return {
                exchangeAddress,
                balance
              };
            })
          )
        };
      });
      return Promise.all(tokens);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return <Fragment />;
};

GetTokens.propTypes = {
  getTokens: PropTypes.func,
  web3: PropTypes.object,
  cash36Contract: PropTypes.object,
  cash36ComplianceContract: PropTypes.object,
  getTokenInstance: PropTypes.func,
  networkError: PropTypes.string
};

export default addCash36(
  connect(
    null,
    { getTokens }
  )(withWeb3(GetTokens))
);
