import {
  GET_TOKENS,
  TOKENS_ERROR,
  ADD_EXCHANGE,
  SET_TRANSACTION_STATUS,
  RESET_TRANSACTION_STATUS,
  REMOVE_EXCHANGE,
  TOGGLE_TRANSFERS,
  UPDATE_COMPLIANCE,
  UPDATE_CONTROLLER,
  UPDATE_CAP,
  GET_EXCHANGE_BALANCE,
  GET_COMPLIANCE_BALANCE,
  DETECT_METAMASK
} from './types';

export const detectMetamask = bool => {
  return {
    type: DETECT_METAMASK,
    payload: bool
  };
};

export const getTokens = getTokensEthereum => async dispatch => {
  try {
    const response = await getTokensEthereum();

    dispatch({
      type: GET_TOKENS,
      payload: response
    });
  } catch (error) {
    dispatch({ type: TOKENS_ERROR, payload: error.message });
    return Promise.reject(error);
  }
};

export const addExchangeToStore = (exchangeAddress, tokenAddress) => {
  return {
    type: ADD_EXCHANGE,
    payload: {
      exchangeAddress,
      tokenAddress
    }
  };
};

export const removeExchangeFromStore = (exchangeAddress, tokenAddress) => {
  return {
    type: REMOVE_EXCHANGE,
    payload: {
      exchangeAddress,
      tokenAddress
    }
  };
};

export const toggleTransfersInStore = (symbol, boolEnabled) => {
  return {
    type: TOGGLE_TRANSFERS,
    payload: {
      symbol,
      boolEnabled
    }
  };
};

export const updateComplianceInStore = (symbol, newComplianceAddress) => {
  return {
    type: UPDATE_COMPLIANCE,
    payload: {
      symbol,
      newComplianceAddress
    }
  };
};

export const updateControllerInStore = (symbol, newControllerAddress) => {
  return {
    type: UPDATE_CONTROLLER,
    payload: {
      symbol,
      newControllerAddress
    }
  };
};

export const updateCapInStore = (symbol, newCapValue) => {
  return {
    type: UPDATE_CAP,
    payload: {
      symbol,
      newCapValue
    }
  };
};

export const getExchangeBalance = (
  exchangeAddress,
  tokenAddress,
  getBalanceFromEthereum
) => async dispatch => {
  try {
    const balance = await getBalanceFromEthereum();
    dispatch({
      type: GET_EXCHANGE_BALANCE,
      payload: {
        exchangeAddress,
        tokenAddress,
        balance
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getComplianceBalance = (
  tokenAddress,
  getBalanceFromEthereum
) => async dispatch => {
  try {
    const response = await getBalanceFromEthereum();
    dispatch({
      type: GET_COMPLIANCE_BALANCE,
      payload: {
        tokenAddress,
        response
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setTransactionStatus = statusObject => {
  return {
    type: SET_TRANSACTION_STATUS,
    payload: statusObject
  };
};

export const resetTransactionStatus = () => {
  return {
    type: RESET_TRANSACTION_STATUS,
    payload: {
      value: '',
      message: ''
    }
  };
};
