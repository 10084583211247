// AUTHENTICATION
export const AUTH_USER = 'AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SET_ROLES = 'AUTH_SET_ROLES';
export const AUTH_CLEAR_ROLES = 'AUTH_CLEAR_ROLES';

// ADMINISTRATION / BLOCKCHAIN
export const DETECT_METAMASK = 'DETECT_METAMASK';
export const GET_TOKENS = 'GET_TOKENS';
export const TOKENS_ERROR = 'TOKENS_ERROR';
export const ADD_EXCHANGE = 'ADD_EXCHANGE';
export const REMOVE_EXCHANGE = 'REMOVE_EXCHANGE';
export const TOGGLE_TRANSFERS = 'TOGGLE_TRANSFERS';
export const UPDATE_COMPLIANCE = 'UPDATE_COMPLIANCE';
export const UPDATE_CONTROLLER = 'UPDATE_CONTROLLER';
export const SET_TRANSACTION_STATUS = 'SET_TRANSACTION_STATUS';
export const RESET_TRANSACTION_STATUS = 'RESET_TRANSACTION_STATUS';
export const GET_EXCHANGE_BALANCE = 'GET_EXCHANGE_BALANCE';
export const GET_COMPLIANCE_BALANCE = 'GET_COMPLIANCE_BALANCE';
export const UPDATE_CAP = 'UPDATE_CAP';

// COMPLIANCE / BACKEND
export const GET_USERS = 'GET_USERS';
export const GET_BLACKLIST_STATUS = 'GET_BLACKLIST_STATUS';
export const TOGGLE_BLACKLIST_STATUS = 'TOGGLE_BLACKLIST_STATUS';
export const BLACKLIST_STATUS_PENDING = 'BLACKLIST_STATUS_PENDING';
export const CONFIRM_USER_IDENTITY = 'CONFIRM_USER_IDENTITY';
export const GET_IDENTITY_STATUS = 'GET_IDENTITY_STATUS';

// USER ORDERS / BACKEND
export const GET_USER_ORDERS = 'GET_USER_ORDERS';
