import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BasicSelectInput from '../../BasicSelectInput';
import TextInput from '../../../../components/TextInput';
import StyledButton from '../../../../components/StyledButton';
import { updateRisk } from '../../../../actions/users';

import './UpdateRiskLevel.scss';

const UpdateRiskLevel = ({ callback, userAddress }) => {
  const [riskLevel, setRiskLevel] = useState('');
  const [riskLevelUpdateReason, setRiskLevelUpdateReason] = useState('');
  const [error, setError] = useState('');

  const update = async () => {
    try {
      await updateRisk(riskLevel, riskLevelUpdateReason, userAddress);
      callback();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="update-risk-level">
      <BasicSelectInput
        name="riskLevel"
        list={['LOW', 'HIGH']}
        label="Override Risk-Level"
        placeholder="Select new Risk-Level"
        value={riskLevel}
        onChange={event => {
          setRiskLevel(event.target.value);
        }}
      />
      <TextInput
        name="risk-level-update-reason"
        value={riskLevelUpdateReason}
        onChange={event => {
          setRiskLevelUpdateReason(event.target.value);
        }}
        placeholder="Comment your reasons"
        label="Reason"
      />
      <StyledButton
        disabled={!riskLevel || !riskLevelUpdateReason}
        onClick={update}
      >
        Update
      </StyledButton>
      <div className="error-text">{error}</div>
    </div>
  );
};

UpdateRiskLevel.propTypes = {
  callback: PropTypes.func,
  userAddress: PropTypes.string
};

export default UpdateRiskLevel;
