export function getFullDate (str) {
  let d = new Date();
  d.setFullYear(d.getFullYear() + 15);
  let centennial = ('' + d.getFullYear()).substring(2, 4);

  let year;
  if (str.substring(0, 2) > centennial) {
    year = '19' + str.substring(0, 2);
  } else {
    year = '20' + str.substring(0, 2);
  }

  return {
    year: year,
    month: str.substring(2, 4),
    day: str.substring(4, 6),
    original: str
  };
}
