import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver/FileSaver';

import { getDocument } from '../../../actions/users';

import './DownloadDoc.scss';

const DownloadDoc = ({ id, name, showName }) => {
  const [error, setError] = useState('');

  const downloadFile = async () => {
    try {
      const data = await getDocument(id);
      const file = new File([data], `${name}`);
      FileSaver.saveAs(file);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="compliance-download-btn__container">
      <button
        className="compliance-btn compliance-download-btn"
        onClick={downloadFile}
      >
        {showName ? name : 'Download'}
      </button>
      <span className="error-text">{error}</span>
    </div>
  );
};

DownloadDoc.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  showName: PropTypes.bool
};

export default DownloadDoc;
