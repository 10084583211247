import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect
} from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import FileInput from '../FileInput';
import DownloadDoc from '../DownloadDoc';
import StyledButton from '../../../components/StyledButton';

import { addDocuments, savePepCheck } from '../../../actions/users';

import './PEPCheck.scss';

const PEPCheck = ({ user, getDetails }) => {
  const { complianceDocuments, username, checklist } = user;
  const pepFile = complianceDocuments.filter(
    doc => doc.type === 'PEP_Result'
  )[0];
  const [file, setFile] = useState(null);
  const [isPep, setIsPep] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const { pepCheckDone } = checklist;

  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const pepFileActions = useMemo(
    () => ({
      add: file => setFile(file),
      remove: () => setFile(null)
    }),
    []
  );

  const onRadioChange = useCallback(event => {
    setIsPep(event.target.value);
  }, []);

  const save = async () => {
    setError('');
    try {
      setSubmitting(true);
      if (!pepFile) {
        await addDocuments(file, 'PEP_Result', username);
      }
      await savePepCheck(username, isPep === 'yes');
      await getDetails(username);
      if (_isMounted.current) {
        if (file) setFile(null);
      }
    } catch (error) {
      setError(error);
    }
    if (_isMounted) setSubmitting(false);
  };

  if (pepCheckDone) {
    return (
      <div className="compliance__pep-check">
        <div className="compliance__pep-check__result">Person {user.isPep ? 'is' : 'is not'} a PEP</div>
        {pepFile ? (
          <DownloadDoc id={pepFile.id} name={pepFile.name} />
        ) : (
          <FileInput
            documentType="PEP_Result"
            changeCallback={pepFileActions.add}
            removeCallback={pepFileActions.remove}
            username={username}
          />
        )}
      </div>
    );
  }

  return (
    <div className="compliance__pep-check">
      {pepFile ? (
        <DownloadDoc id={pepFile.id} name={pepFile.name} />
      ) : (
        <FileInput
          documentType="PEP_Result"
          changeCallback={pepFileActions.add}
          removeCallback={pepFileActions.remove}
          username={username}
        />
      )}

      <div className="compliance__pep-check__radio">
        <div>Is Person a PEP?</div>
        <RadioGroup
          value={isPep}
          onChange={onRadioChange}
          className="compliance__pep-check__radio-group"
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            labelPlacement="start"
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            labelPlacement="start"
            label="No"
          />
        </RadioGroup>
        <div className="compliance__save-result__button">
          <StyledButton
            onClick={save}
            disabled={submitting || ((!file && !pepFile) || !isPep)}
          >
            Save Result
          </StyledButton>
          {error && <div className="error-text">{error}</div>}
        </div>
      </div>
    </div>
  );
};

PEPCheck.propTypes = {
  user: PropTypes.object,
  getDetails: PropTypes.func
};

export default PEPCheck;
