export const calculateAttributes = walletAttributes => {
  if (!walletAttributes) return null;

  const activeAttributesCount = Object.values(walletAttributes).reduce(
    (acc, attributes) => {
      attributes.forEach(attr => {
        if (attr.active) {
          acc[attr.name]++;
        }
      });

      return acc;
    },
    { ATTR_BUY: 0, ATTR_SELL: 0, ATTR_SEND: 0, ATTR_RECEIVE: 0 }
  );

  return Object.keys(activeAttributesCount).reduce((acc, attribute) => {
    acc.push({
      name: attribute,
      active:
        (activeAttributesCount[attribute] /
          Object.values(walletAttributes).length) *
          100 >=
        50
    });

    return acc;
  }, []);
};
