export default theme => ({
  root: {
    color: theme.palette.greys.darkGrey,
    marginLeft: '7rem'
  },
  tab: {
    fontSize: '1.7rem',
    textTransform: 'none',
    fontWeight: '500'
  },
  active: {
    color: theme.palette.common.black,
    fontWeight: '600'
  }
});
