export default {
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  logoStyle: {
    height: '5rem',
    margin: '1rem'
  }
};
