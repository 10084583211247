import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import More from '@material-ui/icons/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { resetOrder, releaseOrder,addAsCorrect,sendViaMail,showDetails } from '../../../actions/userOrders';
import { rolesObj } from '../../../utils/roles.const';

const Actions = ({
  isSell,
  id,
  status,
  errorCallback,
  actionCallback,
  roles
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [reseting, setReseting] = useState(false);
  const [releasing, setReleasing] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onResetClick = async () => {
    setReseting(true);
    try {
      await resetOrder(id);
      actionCallback(id, 'OPEN');
      errorCallback(null);
    } catch (error) {
      errorCallback(error);
    }
    setReseting(false);
  };

  const onAddAsCorrectClick = async () => {
    setReseting(true);
    try {
      await addAsCorrect(id);
      actionCallback(id, 'OPEN');
      errorCallback(null);
    } catch (error) {
      errorCallback(error);
    }
    setReseting(false);
  };

  const onSendViaMailClick = async () => {
    setReseting(true);
    try {
      await sendViaMail(id);
      actionCallback(id, 'OPEN');
      errorCallback(null);
    } catch (error) {
      errorCallback(error);
    }
    setReseting(false);
  };

  const onShowDetailsClick = async () => {
    setReseting(true);
    try {
      await showDetails(id);
      actionCallback(id, 'OPEN');
      errorCallback(null);
    } catch (error) {
      errorCallback(error);
    }
    setReseting(false);
  };

  const onReleaseClick = async () => {
    setReleasing(true);
    try {
      await releaseOrder(id);
      actionCallback(id, 'RELEASED');
      errorCallback(null);
    } catch (error) {
      errorCallback(error);
    }
    setReleasing(false);
  };

  if (roles.indexOf(rolesObj.transaction) === -1) return null;

  return (
    <div className="user-orders__actions">
      <IconButton onClick={handleClick}>
        <More />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {!isSell && status === 'ON_HOLD' && (
          <MenuItem onClick={onResetClick}>
            Mark as Open{' '}
            {reseting && (
              <CircularProgress size={15} style={{ marginLeft: '1rem' }} />
            )}
          </MenuItem>
        )}
        {!isSell && status === 'ON_HOLD' && (
          <MenuItem onClick={onAddAsCorrectClick}>
            Add IBAN to whitelist{' '}
            {reseting && (
              <CircularProgress size={15} style={{ marginLeft: '1rem' }} />
            )}
          </MenuItem>
        )}        

        <MenuItem onClick={onShowDetailsClick}>
          Show Details in Console {' '}
          {reseting && (
            <CircularProgress size={15} style={{ marginLeft: '1rem' }} />
          )}
        </MenuItem>

        <MenuItem onClick={onSendViaMailClick}>
          Send via Mail to Admin{' '}
          {reseting && (
            <CircularProgress size={15} style={{ marginLeft: '1rem' }} />
          )}
        </MenuItem>
   
        {isSell && (status === 'ON_HOLD' || status === 'OPEN') && (
          <MenuItem onClick={onReleaseClick}>
            Release payment{' '}
            {releasing && (
              <CircularProgress size={15} style={{ marginLeft: '1rem' }} />
            )}
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>Export</MenuItem>
      </Menu>
    </div>
  );
};

Actions.propTypes = {
  isSell: PropTypes.bool,
  id: PropTypes.number,
  status: PropTypes.string,
  errorCallback: PropTypes.func,
  actionCallback: PropTypes.func,
  roles: PropTypes.array
};

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles
});

export default connect(
  mapStateToProps,
  null
)(Actions);
