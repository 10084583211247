import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import logoImage from '../../assets/element36-logo.svg';

import styles from './styles';

const Logo = ({ classes }) => (
  <div className={classes.root}>
    <img src={logoImage} alt="LOGO" className={classes.logoStyle} />
  </div>
);

Logo.propTypes = {
  classes: PropTypes.object
};

export default (withStyles(styles)(Logo));
