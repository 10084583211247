import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Token from '../Token';

const TokenList = ({ tokens, disableDropdown }) => {
  return (
    <div className="token-list">
      {tokens.map(token => (
        <Token
          key={token.address}
          {...token}
          disableDropdown={disableDropdown}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({ tokens }) => ({ tokens: tokens.tokens });

TokenList.propTypes = {
  classes: PropTypes.object,
  tokens: PropTypes.arrayOf(PropTypes.object),
  disableDropdown: PropTypes.bool
};

export default connect(mapStateToProps)(TokenList);
