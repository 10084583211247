import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import './SearchBox.scss';

const SearchBox = ({ val, onChange, onSubmit }) => (
  <form onSubmit={onSubmit} className="history__search-box paper">
    <button type="submit">
      <SearchIcon />
    </button>
    <div>
      <TextField
        type="text"
        placeholder="Search"
        value={val}
        onChange={onChange}
        InputProps={{
          disableUnderline: true
        }}
      />
    </div>
  </form>
);

SearchBox.propTypes = {
  val: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SearchBox;
