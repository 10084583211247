import api from '../config/api';
import { GET_USERS } from './types';

import { handleError } from '../utils/error.utils';

export const getUsersList = () => async dispatch => {
  try {
    const response = await api.get(`/compliance/admin/users`);
    dispatch({
      type: GET_USERS,
      payload: response.data
    });
  } catch (error) {
    return handleError(error);
  }
};

export const getUserData = async userAddress => {
  try {
    const response = await api.get(`/compliance/admin/users/${userAddress}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getIdentityStatus = async userAddress => {
  try {
    const response = await api.get(`/compliance/admin/identity/${userAddress}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getBlacklistStatus = async userAddress => {
  try {
    const response = await api.get(
      `/compliance/admin/blacklist/${userAddress}`
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const addToBlacklist = async (userAddress, remarks) => {
  try {
    await api.put(`/compliance/admin/blacklist/${userAddress}`, remarks, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    return handleError(error);
  }
};

export const removeFromBlacklist = async (userAddress, remarks) => {
  try {
    await api.delete(
      `/compliance/admin/blacklist/${userAddress}`,
      {
        data: remarks
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  } catch (error) {
    return handleError(error);
  }
};

export const getDocument = async documentId => {
  try {
    const response = await api.get(
      `/compliance/admin/documents/${documentId}`,
      {
        responseType: 'blob'
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return handleError(error);
  }
};

export const addDocuments = async (file, fileType, userAddress) => {
  try {
    let formData = new FormData();
    formData.append('documentType', fileType);
    formData.append('file', file);

    const response = await api.post(
      `/compliance/admin/document/${userAddress}`,
      formData
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return handleError(error);
  }
};

export const confirmUserIdentity = async userAddress => {
  try {
    await api.post(`/compliance/admin/confirm-identity/${userAddress}`);
  } catch (error) {
    return handleError(error);
  }
};

export const denyUserIdentity = async userAddress => {
  try {
    await api.post(`/compliance/admin/deny-identity/${userAddress}`);
  } catch (error) {
    return handleError(error);
  }
};

export const addNewComment = async (userAddress, newComment) => {
  try {
    await api.post(
      `/compliance/admin/users/comments/${userAddress}`,
      newComment,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  } catch (error) {
    return handleError(error);
  }
};

export const scanMrzData = async mrzData => {
  try {
    const response = await api.post(`/mrz/scan`, mrzData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const sendMrzData = async (userAddress, parsedMrzData) => {
  try {
    await api.post(`/compliance/admin/users/mrz/${userAddress}`, parsedMrzData);
  } catch (error) {
    return handleError(error);
  }
};

export const saveUserProfileVerification = async (
  userAddress,
  plausibleProfile,
  plausibleProfileRemarks
) => {
  try {
    await api.post(`/compliance/admin/users/profile/${userAddress}`, {
      plausibleProfile,
      plausibleProfileRemarks
    });
  } catch (error) {
    return handleError(error);
  }
};

export const savePepCheck = async (userAddress, isPep) => {
  try {
    await api.post(`/compliance/admin/users/pep/${userAddress}`, isPep, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    return handleError(error);
  }
};

export const updateRisk = async (riskLevel, reason, userAddress) => {
  try {
    await api.post(`/compliance/admin/users/risk/${userAddress}`, {
      riskLevel,
      reason
    });
  } catch (error) {
    return handleError(error);
  }
};

export const getUserLimit = async userAddress => {
  try {
    const response = await api.get(
      `/compliance/admin/identity/limit/${userAddress}`
    );

    return response.data.result;
  } catch (error) {
    return handleError(error);
  }
};

export const getUserHistory = async userAddress => {
  try {
    const response = await api.get(`/compliance/admin/history/${userAddress}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getUserAttr = async userAddress => {
  try {
    const response = await api.get(
      `/compliance/admin/attributes/${userAddress}`
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserAttr = async (userAddress, data) => {
  try {
    const response = await api.post(
      `/compliance/admin/attributes/${userAddress}`,
      data
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserFee = async (userAddress, newExchangeFee) => {
  try {
    const response = await api.post(
      `/compliance/admin/users/fee/${userAddress}`,
      newExchangeFee,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const confirmUserByMgmt = async userAddress => {
  try {
    await api.post(
      `/compliance/admin/confirm-identity/management/${userAddress}`
    );
  } catch (error) {
    return handleError(error);
  }
};

export const confirmUserByComplianceSU = async userAddress => {
  try {
    await api.post(
      `/compliance/admin/confirm-identity/compliance-su/${userAddress}`
    );
  } catch (error) {
    return handleError(error);
  }
};
