export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '40rem'
  },
  headline: {
    marginBottom: '3rem',
    fontWeight: '500'
  },
  textField: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.greys.lightGrey}`,
    padding: '1rem',
    borderRadius: '4px',
    '&:focus-within': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    overflow: 'hidden'
  },
  label: {
    fontSize: '1.9rem',
    marginLeft: '1rem',
    marginTop: '1rem'
  },
  input: {
    marginTop: '1rem'
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    backgroundImage: theme.gradients.primary
  },
  forgotPassword: {
    marginTop: '2rem',
    alignSelf: 'flex-start'
  },
  marginBot: {
    marginBottom: '3rem'
  },
  errorMessage: {
    color: '#ff2525',
    marginLeft: '1rem',
    marginTop: '1rem'
  }
});
