
import React from 'react';
import { WEB3_NODE } from '../../config/api';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import Web3 from 'web3';

const Web3Context = React.createContext(null);

class Web3Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      web3: null,
      connection: {
        connected: false,
        isLoading: true, 
        error: null,
      },
    };
  }

  componentDidMount() {
    this.setWeb3(new Web3(Web3.givenProvider || WEB3_NODE))
  }
 
  setWeb3(web3) {
      console.log("setWeb3: "+WEB3_NODE)
    //this.setState({ web3: new ethers.providers.Web3Provider(web3) }, () => {
    this.setState({ web3: new Web3(Web3.givenProvider || WEB3_NODE) }, () => {
      try {
        let nw=this.state.web3.version.network;
        console.log("connected to "+nw)
        this.setState({
            connection: {
            isConnected: true,
            isLoading: false,
            error: null,
            },
        })
      } catch (error) {
        this.setState({
            connection: {
            isConnected: false,
            isLoading: false,
            error: error,
            },
        })
      }

    });
  }


  render() {
    const { web3, connection } = this.state;
    if (this.props.loading && connection.isLoading) {
      return this.props.loading;
    } else if (this.props.error && connection.error) {
      return this.props.error(connection.error);
    }

    return (
      <Web3Context.Provider
        value={{
          web3,
          connection: this.state.connection,
        }}
      >
        {this.props.children}
      </Web3Context.Provider>
    );
  }
}

Web3Provider.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.node,
  error: PropTypes.func,
};

export default Web3Provider;

export const withWeb3 = (WrappedComponent) => {
  class Web3Consumer extends React.Component { // eslint-disable-line
    render() {
      return (
        <Web3Context.Consumer>
          {(context) => (
            <WrappedComponent
              {...this.props}
              web3={context.web3}
              web3State={context.connection}
            />
          )}
        </Web3Context.Consumer>
      );
    }
  }

  if (WrappedComponent.defaultProps) {
    Web3Consumer.defaultProps = { ...WrappedComponent.defaultProps };
  }

  return hoistNonReactStatics(Web3Consumer, WrappedComponent);
};