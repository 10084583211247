import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FileInput from '../FileInput';
import AddButton from '../../../components/AddButton';
import StyledButton from '../../../components/StyledButton';

import { addDocuments } from '../../../actions/users';

import './AdditionalClarifications.scss';

const AdditionalClarificationsHighRisk = ({
  username,
  getDetails,
  docType,
  title
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([null]);

  const add = (file, index) => {
    const updatedList = [...files];
    updatedList[index] = file;
    setFiles([...updatedList]);
  };

  const remove = index => {
    const updatedList = [...files];
    updatedList[index] = null;
    setFiles([...updatedList]);
  };

  const addNewInput = () => {
    const fileInputs = [...files];
    fileInputs[fileInputs.length] = null;
    setFiles([...fileInputs]);
  };

  const save = async () => {
    try {
      setSubmitting(true);
      const filteredInputs = files.filter(f => f);
      await Promise.all([
        ...filteredInputs.map(f => addDocuments(f, docType, username))
      ]);
      await getDetails(username);
      if (files.length) setFiles([]);
    } catch (error) {
      console.warn(error);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (!files.length) setFiles([0]);
  }, [files]);

  useEffect(() => {
    if (files.length) setFiles([]);
  }, [username]);

  return (
    <div className="additional-clarifications">
      <h3>{title}</h3>
      <div className="compliance__risk__inputs">
        {files.map((file, index) => (
          <div key={index}>
            <FileInput
              documentType="Extended_Clarification"
              changeCallback={add}
              removeCallback={remove}
              index={index}
            />
            {index === files.length - 1 && (
              <AddButton onClick={addNewInput} key={index} />
            )}
          </div>
        ))}
      </div>
      <div className="compliance__risk__action">
        <StyledButton
          onClick={save}
          disabled={submitting || !files.filter(f => f).length}
        >
          Save
        </StyledButton>
      </div>
    </div>
  );
};

AdditionalClarificationsHighRisk.propTypes = {
  username: PropTypes.string.isRequired,
  getDetails: PropTypes.func,
  docType: PropTypes.string,
  title: PropTypes.string
};

export default AdditionalClarificationsHighRisk;
