import React from 'react';
import PropTypes from 'prop-types';
import UserIcon from '@material-ui/icons/PermIdentity';
import Check from '@material-ui/icons/Check';
import './DetailsHeader.scss';

const tiers = {
  Tier_0: 'details__header__avatar__badge--tier0',
  Tier_1: 'details__header__avatar__badge--tier1',
  Tier_2: 'details__header__avatar__badge--tier2'
};

const DetailsHeader = ({
  user: {
    username,
    firstName,
    lastName,
    caseId,
    riskEvaluation,
    avatarUrl,
    currentLevel,
    confirmedByComplianceSu
  }
}) => (
  <div className="details__header">
    {confirmedByComplianceSu && (
      <span className="details__header__su-confirmation">
        <Check />
      </span>
    )}
    <div>
      <p>Case-Id: {caseId}</p>
      {riskEvaluation && riskEvaluation.riskLevel && (
        <p>
          Risk-Level:{' '}
          <span
            className={
              riskEvaluation.riskLevel === 'HIGH' ? 'high-risk' : 'low-risk'
            }
          >
            {riskEvaluation.riskLevel}
          </span>
        </p>
      )}

      {firstName && lastName && (
        <h2>
          {firstName} {lastName}
        </h2>
      )}
      <p>{username}</p>
    </div>
    <div className="details__header__avatar">
      {avatarUrl && <img src={avatarUrl} alt={firstName} />}
      {!avatarUrl && !firstName && !lastName && <UserIcon />}
      {!avatarUrl && firstName && lastName && (
        <div className="details__header__avatar__initials">
          {firstName.split('')[0]}
          {lastName.split('')[0]}
        </div>
      )}
      <div
        className={`details__header__avatar__badge ${tiers[currentLevel]}`}
      />
    </div>
  </div>
);

DetailsHeader.propTypes = {
  user: PropTypes.object.isRequired
};

export default DetailsHeader;
