import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import addCash36 from '../../../Cash36Provider';
import { Dialog, Paper, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { getMetamaskAccount } from '../../../utils/metamask.utils';
import StyledButton from '../../../components/StyledButton';
import AddCircle from '@material-ui/icons/AddCircle';
import TransactionSuccess from '../TransactionSuccess';
import TransactionError from '../TransactionError';
import TransactionPending from '../TransactionPending';
import {
  addExchangeToStore,
  setTransactionStatus,
  resetTransactionStatus
} from '../../../actions/tokens';

import styles from './styles';

const AddExchange = ({
  classes,
  web3,
  cash36ExchangesContract,
  transactionStatus,
  setTransactionStatus,
  resetTransactionStatus,
  addExchangeToStore,
  tokenAddress,
  getCallbacks
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleAddExchangeClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (transactionStatus.value === '') {
      setAnchorEl(null);
    }
  };

  const handleInputValue = event => {
    setInputValue(event.target.value);
  };

  const handleAddExchangeSubmit = async () => {
    setTransactionStatus({
      value: 'pending',
      message: ''
    });
    try {
      await addExchangeToBlockchain(inputValue, tokenAddress);
    } catch (error) {
      console.log(error);
      setTransactionStatus({
        value: 'error',
        message: 'There was an input error'
      });
    }
  };

  const handleTransactonSuccess = () => {
    resetTransactionStatus();
    addExchangeToStore(inputValue, tokenAddress);
    setAnchorEl(null);
    setInputValue('');
  };

  const handleTransactionFail = () => {
    setAnchorEl(null);
    resetTransactionStatus();
  };

  const addExchangeToBlockchain = async (exchangeAddress, tokenAddress) => {
    const address = await getMetamaskAccount();

    if (address) {
      const estimate = await cash36ExchangesContract.methods
        .addExchange(exchangeAddress, tokenAddress)
        .estimateGas({ from: address });

      const data = cash36ExchangesContract.methods
        .addExchange(exchangeAddress, tokenAddress)
        .encodeABI();

      const options = {
        from: address,
        to: cash36ExchangesContract.options.address,
        gas: estimate + Math.round(estimate * 0.1),
        nonce: await web3.eth.getTransactionCount(address, 'pending'),
        data
      };

      const callbacks = getCallbacks('addExchange');

      return web3.eth
        .sendTransaction(options)
        .once('transactionHash', callbacks.transactionHash)
        .on('receipt', callbacks.receipt)
        .on('error', callbacks.error);
    } else {
      setTransactionStatus({
        value: 'error',
        message: 'You need to log in to MetaMask'
      });
    }
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <StyledButton onClick={handleAddExchangeClick} className={classes.button}>
        <AddCircle color="primary" />
      </StyledButton>
      <Dialog open={open} onClose={handleClose}>
        <Paper className={classes.paper}>
          {transactionStatus.value === 'pending' && <TransactionPending />}
          {transactionStatus.value === 'success' && <TransactionSuccess />}
          {transactionStatus.value === 'mined' && (
            <Fragment>
              <Typography variant="h6">Mined!</Typography>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleTransactonSuccess}
                fullWidth
              >
                OK
              </StyledButton>
            </Fragment>
          )}
          {transactionStatus.value === 'error' && (
            <TransactionError
              errorMessage={transactionStatus.message}
              handleTransactionFail={handleTransactionFail}
            />
          )}
          {transactionStatus.value === '' && (
            <Fragment>
              <Typography variant="h6">
                Enter the new Exchange you want to add
              </Typography>
              <TextField
                placeholder="Exchange Address"
                value={inputValue}
                onChange={handleInputValue}
                margin="normal"
                fullWidth
              />
              <StyledButton
                disabled={!inputValue}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAddExchangeSubmit}
              >
                Add Exchange
              </StyledButton>
            </Fragment>
          )}
        </Paper>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ transactionStatus }) => ({ transactionStatus });

AddExchange.propTypes = {
  tokenAddress: PropTypes.string,
  transactionStatus: PropTypes.object,
  classes: PropTypes.object,
  addExchangeToStore: PropTypes.func,
  setTransactionStatus: PropTypes.func,
  resetTransactionStatus: PropTypes.func,
  web3: PropTypes.object,
  getCallbacks: PropTypes.func,
  cash36ComplianceContract: PropTypes.object
};

export default addCash36(
  connect(
    mapStateToProps,
    { addExchangeToStore, setTransactionStatus, resetTransactionStatus }
  )(withStyles(styles)(AddExchange))
);
