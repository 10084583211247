import React from 'react';
import PropTypes from 'prop-types';

import './User.scss';

const User = ({ name }) => <div className="user-orders__user">{name}</div>;

User.propTypes = {
  name: PropTypes.string
};

export default User;
