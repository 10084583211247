import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import './UsersSearch.scss';

const UsersSearch = ({ searchTerm, handleSearchChange }) => (
  <div data-testid="users-search" className="users-search paper">
    <div>
      <SearchIcon />
    </div>
    <div>
      <TextField
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          disableUnderline: true
        }}
      />
    </div>
  </div>
);

UsersSearch.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired
};

export default UsersSearch;
