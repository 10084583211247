import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextInput from '../../../components/TextInput';
import AddButton from '../../../components/AddButton';
import StyledButton from '../../../components/StyledButton';
import { updateUserFee } from '../../../actions/users';

import './Fee.scss';

const Fee = ({ exchangeFee, username, getDetails }) => {
  const _isMounted = useRef(true);
  const [activeDialog, setActiveDialog] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [error, setError] = useState('');
  const [val, setVal] = useState('');

  const open = () => setActiveDialog(true);

  const close = () => {
    if (!confirming) setActiveDialog(false);
  };

  const onChange = e => {
    if (e.target.value < 0) return;

    setVal(e.target.value);
  };

  const onUpdate = async e => {
    e.preventDefault();
    setConfirming(true);
    try {
      await updateUserFee(username, val);
      await getDetails(username);

      if (_isMounted.current) {
        close();
        setConfirming(false);
        setError(null);
        setVal('');
      }
    } catch (e) {
      if (_isMounted.current) {
        setError(e);
        setConfirming(false);
      }
    }
  };

  useEffect(() => {
    return () => (_isMounted.current = false);
  }, []);

  return (
    <>
      <div className="compliance-fee">
        <span>Exchange Fee: {exchangeFee}%</span>
        <AddButton isEdit onClick={open} />
      </div>
      <Dialog
        open={activeDialog}
        onClose={close}
        disableBackdropClick={confirming}
        disableEscapeKeyDown={confirming}
        className="fee-dialog"
      >
        <button className="fee-dialog__close" onClick={close}>
          <CloseIcon />
        </button>
        <form onSubmit={onUpdate}>
          <TextInput
            value={val}
            onChange={onChange}
            label=""
            name=""
            type="number"
          />
          <StyledButton
            fullWidth
            type="submit"
            className="fee-dialog__button"
            disabled={confirming || !val}
          >
            {confirming ? <CircularProgress size={20} /> : 'Update Fee'}
          </StyledButton>
          <div className="error-text">{error}</div>
        </form>
      </Dialog>
    </>
  );
};

Fee.propTypes = {
  exchangeFee: PropTypes.number,
  username: PropTypes.string,
  getDetails: PropTypes.func
};

export default Fee;
