import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../../../components/StatusIcon';
import './Checklist.scss';

const Checklist = ({
  list: {
    bankTransferReceived,
    confirmationOfGL,
    mrzScanDone,
    pepCheckDone,
    profileVerificationDone,
    riskEvaluationDone
  },
  riskEvaluation
}) => (
  <div className="compliance__checklist">
    <div className="compliance__checklist__item">
      <StatusIcon valid={bankTransferReceived} /> Initial Bank Transfer of User
      received
    </div>
    <div className="compliance__checklist__item">
      <StatusIcon valid={mrzScanDone} /> MRZ Scan
    </div>
    <div className="compliance__checklist__item">
      <StatusIcon valid={pepCheckDone} /> PEP Check
    </div>
    <div className="compliance__checklist__item">
      <StatusIcon valid={profileVerificationDone} /> Verification of User
      Profile
    </div>
    <div className="compliance__checklist__item">
      <StatusIcon valid={riskEvaluationDone} /> Evaluation of User Risk Profile
    </div>
    {riskEvaluation &&
      riskEvaluationDone &&
      riskEvaluation.riskLevel === 'HIGH' && (
      <div className="compliance__checklist__item">
        <StatusIcon valid={confirmationOfGL} /> For High Risk: Confirmation of
          GL
      </div>
    )}
  </div>
);

Checklist.propTypes = {
  list: PropTypes.object.isRequired,
  riskEvaluation: PropTypes.object
};

export default Checklist;
