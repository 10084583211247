import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default ChildComponent => {
  const ComposedComponent = props =>
    props.isAuthenticated ? <ChildComponent {...props} /> : <Redirect to="/" />;

  const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
  });

  ComposedComponent.propTypes = {
    isAuthenticated: PropTypes.bool,
    history: PropTypes.object
  };

  return connect(mapStateToProps)(ComposedComponent);
};
