import React from 'react';
import PropTypes from 'prop-types';
import addCash36 from '../../../Cash36Provider';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import './MintEvent.scss';

const networkUrls = {
  1: '',
  2: 'morden.',
  3: 'ropsten.',
  4: 'rinkeby.',
  85588558: 'Local.'
};

const MintEvent = ({ hash, networkId }) => {
  console.log(networkId);
  return (
    <div className="user-orders__mint">
      <a
        href={`https://${networkUrls[networkId]}etherscan.io/tx/${hash}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ExitToAppIcon />
      </a>
    </div>
  );
};

MintEvent.propTypes = {
  hash: PropTypes.string,
  networkId: PropTypes.number
};

export default addCash36(MintEvent);
