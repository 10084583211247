import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { detectMetamask } from '../actions/tokens';
import { getCurrentUser, clearUserRoles } from '../actions/auth';
import Header from './Header';
import LoginPage from './LoginPage';
import theme from '../config/theme';
import Administration from '../views/Administration';
import UserOrders from '../views/UserOrders/UserOrders';
import Compliance from '../views/Compliance';

const App = ({
  isAuthenticated,
  detectMetamask,
  clearUserRoles,
  getCurrentUser
}) => {
  const [userError, setUserError] = useState(null);
  const fetchCurrentUser = async () => {
    try {
      await getCurrentUser();
      setUserError(null);
    } catch (error) {
      setUserError(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchCurrentUser();
      return;
    }
    clearUserRoles();
  }, [isAuthenticated]);

  useEffect(() => {
    detectMetamask(typeof window.ethereum !== 'undefined');
  }, []);

  return (
    <Router>
      <Fragment>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          {isAuthenticated && <Header />}
          {userError && (
            <div className="wrapper user-error">
              <div className="error-text">{userError}</div>
            </div>
          )}
          <Route exact path="/" component={LoginPage} />
          <Route path="/administration" component={Administration} />
          <Route path="/compliance" component={Compliance} />
          <Route path="/user-orders" component={UserOrders} />
        </MuiThemeProvider>
      </Fragment>
    </Router>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

App.propTypes = {
  detectMetamask: PropTypes.func,
  isAuthenticated: PropTypes.bool
};

export default connect(
  mapStateToProps,
  { detectMetamask, clearUserRoles, getCurrentUser }
)(App);
