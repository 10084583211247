import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#6bafe6',
      main: '#308fee',
      dark: '#316d9c',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#fff'
    },
    greys: {
      darkGrey: '#949CA8',
      headerGrey: '#F9F9FC',
      lightGrey: '#ededed',
      backgroundGrey: '#FCFCFC'
    },
    helpers: {
      warning: '#EF9A9A',
      onHold: '#FFEDED',
      processing: '#F9F6DF',
      complete: '#ECFAF7'
    },
    text: {
      primary: '#01152c',
      secondary: 'hsla(212, 96%, 9%, 0.5)'
    }
  },
  typography: {
    useNextVariants: true,
    htmlFontSize: 10,
    fontFamily: `"Rubik" sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  gradients: {
    primary: 'linear-gradient(225.95deg, #69B8F2 0%, #017EE5 100%);',
    primaryOverlay:
      'linear-gradient(to right bottom, rgba(107, 175, 230, 0.7), rgba(70, 156, 224, 0.7))'
  },
  overrides: {
    MuiPaper: {
      root: {
        borderRadius: '10px'
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'none'
        }
      }
    },
    MuiCircularProgress: {
      colorSecondary: {
        color: '#fff'
      }
    },
    MuiExpansionPanel: {
      root: {
        borderRadius: '10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.05)'
      }
    },
    MuiTab: {
      root: {
        selected: {
          color: '#030303'
        }
      }
    }
  }
});
