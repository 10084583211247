import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import statuses from './statuses';

import './Status.scss';

const Status = ({ status }) => {
  const statusObj = useMemo(() => {
    return statuses[status]
      ? { ...statuses[status] }
      : {
        ...statuses.default,
        text: status
          .toLowerCase()
          .split('_')
          .join(' ')
      };
  }, [status]);

  return (
    <div className="activity-table-status-wrapper">
      <div className={`activity-table-status ${statusObj.cssClass}`}>
        {statusObj.Icon}
        <div className="activity-table-status__text">{statusObj.text}</div>
      </div>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired
};

export default Status;
