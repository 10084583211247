import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Paper, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import { getMetamaskAccount } from '../../../utils/metamask.utils';
import {
  updateControllerInStore,
  setTransactionStatus,
  resetTransactionStatus
} from '../../../actions/tokens';
import addCash36 from '../../../Cash36Provider';
import StyledButton from '../../../components/StyledButton';
import TransactionSuccess from '../TransactionSuccess';
import TransactionError from '../TransactionError';
import TransactionPending from '../TransactionPending';

import styles from './styles';

const UpdateControllerAddress = ({
  classes,
  web3,
  cash36Contract,
  symbol,
  controllerAddress,
  transactionStatus,
  setTransactionStatus,
  resetTransactionStatus,
  updateControllerInStore,
  getCallbacks
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleClose = () => {
    if (transactionStatus.value === '') {
      setAnchorEl(null);
    }
  };

  const handleUpdateControllerAddressClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateControllerAddressSubmit = async () => {
    setTransactionStatus({
      value: 'pending',
      message: ''
    });
    try {
      await updateControllerAddressOnBlockchain(symbol, inputValue);
    } catch (error) {
      console.log(error);
      setTransactionStatus({
        value: 'error',
        message: 'There was an error with the request'
      });
    }
  };

  const handleTransactonSuccess = () => {
    resetTransactionStatus();
    updateControllerInStore(symbol, inputValue);
    setAnchorEl(null);
    setInputValue('');
  };

  const handleTransactionFail = () => {
    resetTransactionStatus();
    setAnchorEl(null);
  };

  const updateControllerAddressOnBlockchain = async (
    symbol,
    newControllerAddress
  ) => {
    const address = await getMetamaskAccount();

    if (address) {
      const estimate = await cash36Contract.methods
        .updateController(symbol, newControllerAddress)
        .estimateGas({ from: address });

      const data = cash36Contract.methods
        .updateController(symbol, newControllerAddress)
        .encodeABI();

      const options = {
        from: address,
        to: cash36Contract.options.address,
        gas: estimate + Math.round(estimate * 0.1),
        nonce: await web3.eth.getTransactionCount(address, 'pending'),
        data
      };

      const callbacks = getCallbacks('updateController');

      return web3.eth
        .sendTransaction(options)
        .once('transactionHash', callbacks.transactionHash)
        .on('receipt', callbacks.receipt)
        .on('error', callbacks.error);
    } else {
      setTransactionStatus({
        value: 'error',
        message: 'You need to log in to MetaMask'
      });
    }
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <StyledButton variant="text" onClick={handleUpdateControllerAddressClick}>
        <EditIcon className={classes.icon} />
      </StyledButton>
      <Dialog open={open} onClose={handleClose}>
        <Paper className={classes.paper}>
          {transactionStatus.value === 'pending' && <TransactionPending />}
          {transactionStatus.value === 'success' && <TransactionSuccess />}
          {transactionStatus.value === 'mined' && (
            <Fragment>
              <Typography variant="h6">Controller Address Updated</Typography>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleTransactonSuccess}
              >
                OK
              </StyledButton>
            </Fragment>
          )}
          {transactionStatus.value === 'error' && (
            <TransactionError
              errorMessage={transactionStatus.message}
              handleTransactionFail={handleTransactionFail}
            />
          )}
          {transactionStatus.value === '' && (
            <Fragment>
              <Typography variant="h6">Edit controller address</Typography>
              <Typography variant="subtitle1">
                {' '}
                Current address: {controllerAddress}
              </Typography>
              <TextField
                placeholder="New address"
                value={inputValue}
                onChange={event => {
                  setInputValue(event.target.value);
                }}
                margin="normal"
                fullWidth
              />
              <StyledButton
                disabled={!inputValue}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleUpdateControllerAddressSubmit}
              >
                Submit new address
              </StyledButton>
            </Fragment>
          )}
        </Paper>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ transactionStatus }) => ({ transactionStatus });
const mapDispatchToProps = {
  updateControllerInStore,
  setTransactionStatus,
  resetTransactionStatus
};

UpdateControllerAddress.propTypes = {
  classes: PropTypes.object,
  transactionStatus: PropTypes.object,
  setTransactionStatus: PropTypes.func,
  resetTransactionStatus: PropTypes.func,
  updateControllerInStore: PropTypes.func,
  symbol: PropTypes.string,
  getCallbacks: PropTypes.func,
  cash36Contract: PropTypes.object,
  web3: PropTypes.object,
  controllerAddress: PropTypes.string
};

export default addCash36(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(UpdateControllerAddress))
);
