import { combineReducers } from 'redux';

import metamaskReducer from './metamask';
import tokensReducer from './tokens';
import usersReducer from './users';
import userOrdersReducer from './userOrders';
import transactionStatusReducer from './transactionStatus';
import authReducer from './auth';

export default combineReducers({
  auth: authReducer,
  metamask: metamaskReducer,
  tokens: tokensReducer,
  users: usersReducer,
  userOrders: userOrdersReducer,
  transactionStatus: transactionStatusReducer
});
