import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import companyIcon from '../../../assets/company.svg';
import api, { COMPANY_ADDRESS } from '../../../config/api';

import './Company.scss';

const Company = () => {
  const _isMounted = useRef(true);
  const [company, setCompany] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  const fetchCompanyBalance = async () => {
    setFetching(true);
    try {
      const response = await api.get(`/compliance/company/${COMPANY_ADDRESS}`);
      if (_isMounted.current) {
        setCompany(response.data);
        setFetching(false);
      }
    } catch (err) {
      if (_isMounted.current) {
        const { error, message } = err.response.data;
        setError(`${error}: ${message}`);
        setFetching(false);
      }
    }
  };

  useEffect(() => {
    fetchCompanyBalance();

    return () => (_isMounted.current = false);
  }, []);

  return (
    <div className="company-details paper">
      {fetching && (
        <div className="company-details__loader">
          <CircularProgress size={40} />
        </div>
      )}
      {error && <p className="administration__error">{error}</p>}
      {company && (
        <>
          <img src={companyIcon} alt="" />
          <div className="company-details__account">
            <span>{company.name}</span>
            {COMPANY_ADDRESS}
          </div>
          <div className="company-details__balance">
            <div className="company-details__balance__item">
              <span>EUR Balance</span>
              {company.eur36Balance} EUR36
            </div>
            <div>
              <div className="company-details__balance__item">
                <span>CHF Balance</span>
                {company.chf36Balance} CHF36
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Company;
