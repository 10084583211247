import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Folder } from '@material-ui/icons';

import API from '../../../config/api';
import './CheckVideos.scss';

const CheckVideos = ({ userId }) => {
  const [link, setLink] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(
          `/compliance/admin/users/files/${userId}`
        );

        if (response.status === 204) {
          setLink(null);
          return;
        }

        setLink(response.data);
      } catch (err) {
        setLink(null);
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    <div
      style={{
        opacity: link ? 1 : 0.5,
        cursor: link ? 'pointer' : 'default'
      }}
      className="check-videos"
    >
      {link ? (
        <a href={link}>
          <Folder />
        </a>
      ) : (
        <Folder />
      )}
    </div>
  );
};

CheckVideos.propTypes = {
  userId: PropTypes.string
};

export default CheckVideos;
