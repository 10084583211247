import React from 'react';
import PropTypes from 'prop-types';

import DownloadDoc from '../DownloadDoc';

import './UserDocuments.scss';

const UserDocuments = ({ user }) => {
  const { userDocuments, selfieCode } = user;

  const docs = userDocuments.reduce((acc, { type, name, id }) => {
    acc[type] = { name, id };
    return acc;
  }, {});

  return (
    <div className="compliance-documents">
      <div>
        <div className="compliance-documents__item">
          <span>ID Front/Passport</span>
          {!docs.ID_Front ? (
            <span className="invalid">Missing</span>
          ) : (
            <DownloadDoc id={docs.ID_Front.id} name={docs.ID_Front.name} />
          )}
        </div>
        <div className="compliance-documents__item">
          <span>ID Back</span>
          {!docs.ID_Back ? (
            <span className="invalid">Missing</span>
          ) : (
            <DownloadDoc id={docs.ID_Back.id} name={docs.ID_Back.name} />
          )}
        </div>
      </div>
      <div>
        <div className="compliance-documents__item">
          <span>ID Selfie</span>
          {!docs.ID_Selfie ? (
            <span className="invalid">Missing</span>
          ) : (
            <DownloadDoc id={docs.ID_Selfie.id} name={docs.ID_Selfie.name} />
          )}
        </div>
        <div className="compliance-documents__item">
          <span>Selfie Code</span>
          <span className={!selfieCode ? 'invalid' : ''}>
            {selfieCode || 'Missing'}
          </span>
        </div>
        <div className="compliance-documents__item">
          <span>Address Verification</span>
          {!docs.Utility_Bill ? (
            <span className="invalid">Missing</span>
          ) : (
            <DownloadDoc
              id={docs.Utility_Bill.id}
              name={docs.Utility_Bill.name}
            />
          )}
        </div>
      </div>
    </div>
  );
};

UserDocuments.propTypes = {
  user: PropTypes.object.isRequired,
  sendMrzData: PropTypes.func
};

export default UserDocuments;
