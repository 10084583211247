import React from 'react';
import PropTypes from 'prop-types';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import { withStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import TodayIcon from '@material-ui/icons/Today';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import styles from './styles';

const DateRange = ({ classes, onChange, from, to }) => {
  const handleStartDateChange = date => {
    onChange(date, true);
  };

  const handleEndDateChange = date => {
    onChange(date, false);
  };

  return (
    <div className={`${classes.root} paper`}>
      <div className={classes.picker}>
        <div className={classes.label}>
          <TodayIcon className={classes.icon} />
          <span>Start</span>
        </div>
        <div className={classes.pickerInputBox}>
          <InlineDatePicker
            autoOk
            value={from}
            onChange={handleStartDateChange}
            format={'DD/MM/YYYY'}
            onlyCalendar
            rightArrowIcon={<RightArrowIcon />}
            leftArrowIcon={<LeftArrowIcon />}
            InputProps={{
              disableUnderline: true,
              placeholder: 'DD/MM/YYYY',
              className: classes.input
            }}
            disableFuture
          />
          <DownArrowIcon className={classes.arrowIcon} />
        </div>
      </div>
      <div className={classes.picker}>
        <div className={classes.label}>
          <EventIcon className={classes.icon} />
          <span>End</span>
        </div>
        <div className={classes.pickerInputBox}>
          <InlineDatePicker
            autoOk
            value={to}
            onChange={handleEndDateChange}
            format={'DD/MM/YYYY'}
            onlyCalendar
            rightArrowIcon={<RightArrowIcon />}
            leftArrowIcon={<LeftArrowIcon />}
            InputProps={{
              disableUnderline: true,
              placeholder: 'DD/MM/YYYY',
              className: classes.input
            }}
            disableFuture
          />
          <DownArrowIcon className={classes.arrowIcon} />
        </div>
      </div>
    </div>
  );
};

DateRange.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  from: PropTypes.object,
  to: PropTypes.object
};

export default withStyles(styles)(DateRange);
