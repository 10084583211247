import React from 'react';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';

import './AddButton.scss';

const AddButton = ({ type = 'text', onClick, isEdit }) => {
  return (
    <button
      type={type}
      className="add-button"
      onClick={onClick}
      data-testid="add-button"
    >
      {isEdit ? <Edit /> : <Add />}
    </button>
  );
};

AddButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  isEdit: PropTypes.bool
};

export default AddButton;
