import React from 'react';
import PropTypes from 'prop-types';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import HeaderMenu from '../HeaderMenu';

import styles from './styles';

const HeaderInfo = ({ classes }) => (
  <div className={classes.root}>
    <NotificationsIcon className={classes.icon} />
    <Avatar
      alt={`admin`} src={null}
      className={classes.avatar}>
      Admin
    </Avatar>
    <HeaderMenu />
  </div>
);

HeaderInfo.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(HeaderInfo);
