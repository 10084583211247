import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import requireAuth from '../../components/requireAuth';
import { getUsersList } from '../../actions/users';
import UserDetails from './UserDetails';
import UsersList from './UsersList';
import UsersSearch from './UsersSearch';
import RefreshButton from '../../components/RefreshButton';

import './Compliance.scss';

const Compliance = ({ users, getUsersList }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const selectUser = userAddress => {
    setSelectedUser(userAddress);
  };

  useEffect(() => {
    callGetUsersList();
  }, []);

  const callGetUsersList = async () => {
    try {
      await getUsersList();
    } catch (error) {
      console.log(error);
    }
  };

  if (Object.keys(users).length === 0) {
    return (
      <div className="wrapper compliance">
        <div className="compliance__no-users">
          <span>There are no current users</span>
          <RefreshButton onClick={() => getUsersList()} />
        </div>
      </div>
    );
  }

  return (
    <div className="wrapper compliance">
      <div className="compliance__users">
        <div className="compliance__users__top">
          <RefreshButton onClick={getUsersList} />

          <UsersSearch
            searchTerm={searchTerm}
            handleSearchChange={event => {
              setSearchTerm(event.target.value);
            }}
          />
        </div>

        <UsersList
          users={users}
          onUserClick={selectUser}
          searchTerm={searchTerm}
          selectedUser={selectedUser}
        />
      </div>
      <UserDetails userAddress={selectedUser} users={users} />
    </div>
  );
};

const mapStateToProps = ({ users }) => ({ users });

Compliance.propTypes = {
  getUsersList: PropTypes.func,
  users: PropTypes.object
};

export default requireAuth(
  connect(
    mapStateToProps,
    { getUsersList }
  )(Compliance)
);
