import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../../../components/StatusIcon';

const checklistMap = {
  bankTransferReceived: 'Initial Bank Transfer of User received',
  mrzScanDone: 'MRZ Scan',
  pepCheckDone: 'PEP Check',
  profileVerificationDone: 'Verification of User Profile',
  riskEvaluationDone: 'Evaluation of User Risk Profile',
  confirmationOfGL: 'For High Risk: Confirmation of GL'
};

const CheckList = ({ list, riskLevel }) => (
  <div className="compliance__action-buttons__checklist">
    <div className="compliance__action-buttons__confirm-box__message">
      The following checklist items are not yet verified
    </div>
    {Object.keys(list)
      .filter(el => {
        return list[el] === false;
      })
      .map(el => {
        if (
          el === 'confirmationOfGL' &&
          (list.riskEvaluationDone === false || riskLevel === 'LOW')
        ) {
          return null;
        }
        return (
          <div key={el} className="user-details-bottom__checklist-item">
            <StatusIcon valid={list[el]} /> {checklistMap[el]}
          </div>
        );
      })}
  </div>
);

CheckList.propTypes = {
  list: PropTypes.object,
  riskLevel: PropTypes.string
};

export default CheckList;
