import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Nav from '../Nav';
import Logo from '../Logo';
import HeaderInfo from './HeaderInfo';

import styles from './styles';

const Header = ({ classes, authed, logout, history }) => {
  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar className={classes.toolbar} >
        <div className={classes.menuBar}>
          <Logo />
          <Nav />
        </div>
        <HeaderInfo />
      </Toolbar>
    </AppBar>

  );
};

Header.propTypes = {
  authed: PropTypes.bool
};

export default connect(null, null, null, { pure: false })(withStyles(styles)(Header));
