import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const ConfirmProgress = ({ deny }) => (
  <div className="compliance__action-buttons__loading">
    <CircularProgress />
    <div>
      {deny ? 'Denying' : 'Confirming'} user - Please wait, this will take a
      moment...
    </div>
  </div>
);

ConfirmProgress.propTypes = {
  deny: PropTypes.bool
};

export default ConfirmProgress;
