import {
  GET_TOKENS,
  TOKENS_ERROR,
  ADD_EXCHANGE,
  REMOVE_EXCHANGE,
  TOGGLE_TRANSFERS,
  UPDATE_COMPLIANCE,
  UPDATE_CONTROLLER,
  UPDATE_CAP,
  GET_EXCHANGE_BALANCE,
  GET_COMPLIANCE_BALANCE
} from '../actions/types';

const initialState = {
  tokens: [],
  tokensError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TOKENS:
      return { ...state, tokens: action.payload, tokensError: null };
    case TOKENS_ERROR:
      return { ...state, tokensError: action.payload };
    case ADD_EXCHANGE:
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.address === action.payload.tokenAddress) {
            const newExchange = {
              exchangeAddress: action.payload.exchangeAddress,
              balance: ''
            };
            return {
              ...token,
              allowedExchanges: [...token.allowedExchanges, newExchange]
            };
          }
          return token;
        })
      };
    case GET_EXCHANGE_BALANCE: {
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.address === action.payload.tokenAddress) {
            const allowedExchanges = token.allowedExchanges.map(exchange => {
              if (exchange.exchangeAddress === action.payload.exchangeAddress) {
                return {
                  ...exchange,
                  balance: action.payload.balance
                };
              }
              return exchange;
            });
            return {
              ...token,
              allowedExchanges
            };
          }
          return token;
        })
      };
    }
    case GET_COMPLIANCE_BALANCE: {
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.address === action.payload.tokenAddress) {
            return {
              ...token,
              complianceBalance: {
                ...action.payload.response
              }
            };
          }
          return token;
        })
      };
    }
    case REMOVE_EXCHANGE:
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.address === action.payload.tokenAddress) {
            return {
              ...token,
              allowedExchanges: token.allowedExchanges.filter(
                exchange =>
                  exchange.exchangeAddress !== action.payload.exchangeAddress
              )
            };
          }
          return token;
        })
      };
    case TOGGLE_TRANSFERS:
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.symbol === action.payload.symbol) {
            return {
              ...token,
              transfersEnabled: action.payload.boolEnabled
            };
          }
          return token;
        })
      };
    case UPDATE_COMPLIANCE:
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.symbol === action.payload.symbol) {
            return {
              ...token,
              complianceAddress: action.payload.newComplianceAddress
            };
          }
          return token;
        })
      };
    case UPDATE_CONTROLLER:
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.symbol === action.payload.symbol) {
            return {
              ...token,
              controllerAddress: action.payload.newControllerAddress
            };
          }
          return token;
        })
      };
    case UPDATE_CAP:
      return {
        ...state,
        tokens: state.tokens.map(token => {
          if (token.symbol === action.payload.symbol) {
            return {
              ...token,
              cap: action.payload.newCapValue
            };
          }
          return token;
        })
      };

    default:
      return state;
  }
};
