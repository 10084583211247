import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TokenDetails from '../TokenDetails';
import TokenPanel from '../TokenPanel';
import Dropdown from '../../../components/Dropdown';
import { rolesObj } from '../../../utils/roles.const';

import './Token.scss';

const Token = props => {
  const isDisabled = props.roles.indexOf(rolesObj.admin) === -1;

  return (
    <div className="new-token paper">
      <Dropdown
        disableDropdown={props.disableDropdown || isDisabled}
        title={<TokenDetails {...props} />}
        children={<TokenPanel {...props} />}
      />
    </div>
  );
};

Token.propTypes = {
  disableDropdown: PropTypes.bool
};

const mapStateToProps = ({ auth }) => ({
  roles: auth.roles
});

export default connect(
  mapStateToProps,
  null
)(Token);
