import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import RefreshButton from '../../../components/RefreshButton';
import FilterByStatus from '../FilterByStatus';
import DateRange from '../DateRange';
import SearchBox from '../SearchBox';
import './Controls.scss';

const Controls = ({ getOrders }) => {
  const [filters, setFilters] = useState({
    from: null,
    to: null,
    status: '',
    filterValue: ''
  });

  const parseDates = val => ({
    to: val.to ? val.to.format('DD.MM.YYYY') : null,
    from: val.from ? val.from.format('DD.MM.YYYY') : null
  });

  const onSearchChange = e => {
    setFilters({ ...filters, filterValue: e.target.value });
  };

  const onStatusChange = useCallback(status => {
    setFilters({
      ...filters,
      status
    });
    getOrders({
      ...filters,
      status,
      ...parseDates(filters)
    });
  }, [filters]);

  const onDateChange = useCallback((date, isStartDate) => {
    const filterVal = { ...filters };
    isStartDate ? (filterVal.from = date) : (filterVal.to = date);

    setFilters({
      ...filterVal
    });
    getOrders({
      ...filterVal,
      ...parseDates(filterVal)
    });
  }, [filters]);

  const submitSearch = e => {
    e.preventDefault();
    getOrders(filters);
  };

  const resetFilters = () => {
    const val = {
      from: null,
      to: null,
      status: '',
      filterValue: ''
    };
    setFilters(val);
    getOrders(val);
  };

  return (
    <div className="user-orders__controls">
      <div>
        <DateRange
          onChange={onDateChange}
          from={filters.from}
          to={filters.to}
        />
      </div>
      <div>
        <SearchBox
          val={filters.filterValue}
          onChange={onSearchChange}
          onSubmit={submitSearch}
        />
      </div>
      <div>
        <FilterByStatus onChangeCallback={onStatusChange} />
      </div>
      <div>
        <RefreshButton
          onClick={() =>
            getOrders({
              ...filters,
              ...parseDates(filters)
            })
          }
        />
      </div>
      {(filters.to ||
        filters.from ||
        filters.filterValue ||
        filters.status) && (
        <button className="user-orders__control__reset" onClick={resetFilters}>
          Reset all filters
        </button>
      )}
    </div>
  );
};

Controls.propTypes = {
  getOrders: PropTypes.func
};

export default Controls;
