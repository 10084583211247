import React from 'react';
import PropTypes from 'prop-types';

import warningIcon from '../../../assets/warning-icon.svg';
import UsersListBlock from '../UsersListBlock';

import './UsersList.scss';

const UsersList = ({ users, onUserClick, searchTerm, selectedUser }) => {
  const allUsers = Object.values(users).filter(user => {
    const { firstName, lastName, username } = user;
    const name = `${firstName} ${lastName}`;

    if (!name) {
      return username.toLowerCase().includes(searchTerm.toLowerCase());
    }

    const filterCondition =
      username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      name.toLowerCase().includes(searchTerm.toLowerCase());

    return filterCondition;
  });

  const awaitingVerificationUsers = allUsers.filter(
    user => user.currentProcessStatus === 'AWAITING_VERIFICATION'
  );

  const otherUsers = {};

  allUsers
    .filter(user => user.currentProcessStatus !== 'AWAITING_VERIFICATION')
    .forEach(user => {
      if (user.lastName === null) {
        otherUsers['0'] = otherUsers['0'] ? [...otherUsers['0'], user] : [user];
      } else {
        const firstLetter = user.lastName.split('')[0].toLowerCase();

        otherUsers[firstLetter] = otherUsers[firstLetter]
          ? [...otherUsers[firstLetter], user]
          : [user];
      }
    });

  return (
    <div className="compliance__users-list">
      <UsersListBlock
        users={awaitingVerificationUsers}
        icon={warningIcon}
        onUserClick={onUserClick}
        selectedUser={selectedUser}
      />
      {Object.keys(otherUsers)
        .sort(() => true)
        .reverse()
        .map((letter, index) => {
          return (
            <UsersListBlock
              key={index}
              users={otherUsers[letter]}
              letter={letter}
              onUserClick={onUserClick}
              selectedUser={selectedUser}
            />
          );
        })}
    </div>
  );
};

UsersList.propTypes = {
  users: PropTypes.object,
  onUserClick: PropTypes.func,
  searchTerm: PropTypes.string,
  selectedUser: PropTypes.string
};

export default UsersList;
