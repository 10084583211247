import { GET_USERS, GET_IDENTITY_STATUS } from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      const users = action.payload.map(user => {
        return {
          ...state,
          [user.username]: {
            ...state[user.username],
            ...user
          }
        };
      });
      return Object.assign({}, state, ...users);
    case GET_IDENTITY_STATUS:
      return {
        ...state,
        [action.payload.userAddress]: {
          ...state[action.payload.userAddress],
          identityStatus: action.payload.identityStatus
        }
      };
    default:
      return state;
  }
};
