import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const DialogHeader = ({ handleDialogClose }) => (
  <button className="blacklist-user-dialog__close" onClick={handleDialogClose}>
    <CloseIcon />
  </button>
);

DialogHeader.propTypes = {
  handleDialogClose: PropTypes.func
};

export default DialogHeader;
