export async function getMetamaskAccount () {
  let { ethereum } = window;
  if (ethereum === undefined) return null;
  try {
    const address = await window.ethereum.enable();
    return address[0];
  } catch (error) {
    return Promise.reject(error);
  }
}
