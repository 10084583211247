import React from 'react';
import PropTypes from 'prop-types';

import PEPCheck from '../PEPCheck';
import MRZ from '../MRZ';
import UserProfileVerification from '../UserProfileVerification';
import UserRiskProfileEvaluation from '../UserRiskProfileEvaluation';

import './ComplianceDesk.scss';

const ComplianceDesk = ({ user, getDetails }) => {
  const {
    riskEvaluation,
    checklist: {
      riskEvaluationDone,
      profileVerificationDone,
      pepCheckDone,
      mrzScanDone
    },
    confirmedByMgmt
  } = user;

  return (
    <div className="compliance-desk">
      <div className="user-details__section">
        <h3>
          1){' '}
          {mrzScanDone
            ? 'MRZ Scan Results'
            : 'Run MRZ Scan and verify User Data'}
        </h3>
        <MRZ user={user} getDetails={getDetails} />
      </div>
      <div className="user-details__section">
        <h3>
          2){' '}
          {pepCheckDone
            ? 'PEP Check Results'
            : 'Run and upload the PEP Check Results'}
        </h3>
        <PEPCheck user={user} getDetails={getDetails} />
      </div>
      <div className="user-details__section">
        <h3>
          3) Verification of User Profile {profileVerificationDone && 'Results'}
        </h3>
        <UserProfileVerification user={user} getDetails={getDetails} />
      </div>
      <div className="user-details__section">
        <h3>4) User Risk Profile Evaluation</h3>
        {riskEvaluationDone ? (
          <UserRiskProfileEvaluation user={user} getDetails={getDetails} />
        ) : (
          <div className="mrz-data__no-scan-run">
            Risk evaluation will be performed, once the User passes Step 4 of
            the process (User Profile).
          </div>
        )}
      </div>
      {riskEvaluationDone && riskEvaluation.riskLevel === 'HIGH' && (
        <div className="user-details__section --risk">
          5.)
          {confirmedByMgmt ? (
            <span className="confirmation-by-gl">
              Management confirmed this customers ( see History for Details)
            </span>
          ) : (
            <span className="confirmation-by-gl --alt">
              Confirmation of High Risk Customers by Management needed
            </span>
          )}
        </div>
      )}
    </div>
  );
};

ComplianceDesk.propTypes = {
  user: PropTypes.object,
  getDetails: PropTypes.func
};

export default ComplianceDesk;
