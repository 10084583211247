import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import UpdateRiskLevel from './UpdateRiskLevel';
import AdditionalClarifications from '../AdditionalClarifications';
import AddButton from '../../../components/AddButton';
import DownloadDoc from '../DownloadDoc';

import './UserRiskProfileEvaluation.scss';

function parseValue(value) {
  if (value.includes('LessThan')) {
    const parsedValue = value.split('LessThan')[1].split('k')[0] + '.000';
    return `< ${parsedValue} CHF/EUR`;
  }

  if (value.includes('From')) {
    const parsedValues = value
      .split('From')[1]
      .split('to')
      .map(value => value.split('k')[0]);

    return `${parsedValues[0]} - ${parsedValues[1] + '.000'} CHF/EUR`;
  }

  if (value.includes('MoreThan')) {
    const parsedValue = value.split('MoreThan')[1].split('k')[0] + '.000';

    return `> ${parsedValue} CHF/EUR`;
  }
}

const UserRiskProfileEvaluation = React.memo(({ user, getDetails }) => {
  const [activeModal, setActiveModal] = useState(false);

  const {
    riskEvaluation,
    country,
    nationality,
    nationality2,
    nationality3,
    nationality4,
    industry,
    industryOther,
    transactionVolume,
    isPep,
    username,
    complianceDocuments
  } = user;

  const userFiles = complianceDocuments.filter(
    doc => doc.type === 'Extended_Clarification'
  );

  const openModal = () => setActiveModal(true);
  const closeModal = () => setActiveModal(false);
  const updateRiskCallback = () => {
    closeModal();
    getDetails(username);
  };

  return (
    <Fragment>
      <div className="user-risk-evaluation">
        <div>
          <div className="user-risk-profile-evaluation__column">
            <h5>Criteria</h5>
            <span>Country</span>
            <span>Nationality 1</span>
            {nationality2 && <span>Nationality 2</span>}
            {nationality3 && <span>Nationality 3</span>}
            {nationality4 && <span>Nationality 4</span>}
            <span>Industry</span>
            <span>Volume</span>
            <span>PEP</span>
          </div>
          <div className="user-risk-profile-evaluation__column">
            <h5>Value</h5>
            <span>{country.name}</span>
            <span>{nationality.name}</span>
            {nationality2 && <span>{nationality2.name}</span>}
            {nationality3 && <span>{nationality3.name}</span>}
            {nationality4 && <span>{nationality4.name}</span>}
            <span>
              {industry.toLowerCase() === 'other' ? industryOther : industry}
            </span>
            <span>{parseValue(transactionVolume)}</span>
            <span>{isPep ? 'Yes' : 'No'}</span>
          </div>
          <div className="user-risk-profile-evaluation__column">
            <h5>Risk-Level</h5>
            <span
              className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.countryRisk.toLowerCase()}`}
            >
              {riskEvaluation.countryRisk.toLowerCase()}
            </span>
            <span
              className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.nationalityRisk.toLowerCase()}`}
            >
              {riskEvaluation.nationalityRisk.toLowerCase()}
            </span>
            {riskEvaluation.nationality2Risk && (
              <span
                className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.nationality2Risk.toLowerCase()}`}
              >
                {riskEvaluation.nationality2Risk.toLowerCase()}
              </span>
            )}
            {riskEvaluation.nationality3Risk && (
              <span
                className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.nationality3Risk.toLowerCase()}`}
              >
                {riskEvaluation.nationality3Risk.toLowerCase()}
              </span>
            )}
            {riskEvaluation.nationality4Risk && (
              <span
                className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.nationality4Risk.toLowerCase()}`}
              >
                {riskEvaluation.nationality4Risk.toLowerCase()}
              </span>
            )}
            <span
              className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.industryRisk.toLowerCase()}`}
            >
              {riskEvaluation.industryRisk.toLowerCase()}
            </span>
            <span
              className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.volumeRisk.toLowerCase()}`}
            >
              {riskEvaluation.volumeRisk.toLowerCase()}
            </span>
            <span
              className={`user-risk-profile-evaluation__risk-level --capitalize ${riskEvaluation.pepRisk.toLowerCase()}`}
            >
              {riskEvaluation.pepRisk.toLowerCase()}
            </span>
          </div>
        </div>
        <div>
          <AddButton isEdit onClick={openModal} />
          <div>Calculated Risk Evaluation</div>
          <div
            className={`--capitalize ${riskEvaluation.calculatedRiskLevel.toLowerCase()}`}
          >
            {riskEvaluation.calculatedRiskLevel.toLowerCase()}
          </div>
        </div>
        <div>
          <div>
            <strong>Final Risk Evaluation</strong>
          </div>
          <div
            className={`--capitalize ${
              riskEvaluation.riskUpdated
                ? riskEvaluation.riskLevel.toLowerCase()
                : riskEvaluation.calculatedRiskLevel.toLowerCase()
            }`}
          >
            {riskEvaluation.riskUpdated
              ? riskEvaluation.riskLevel.toLowerCase()
              : riskEvaluation.calculatedRiskLevel.toLowerCase()}
          </div>
          {riskEvaluation.riskUpdated && (
            <p>{riskEvaluation.reasonForOverride}</p>
          )}
        </div>

        {riskEvaluation.riskLevel === 'HIGH' && (
          <AdditionalClarifications
            username={username}
            getDetails={getDetails}
            docType="Extended_Clarification"
            title="Additional Clarifications for High Risk Customers"
          />
        )}
        <div className="compliance__risk__files">
          {userFiles.map(file => (
            <DownloadDoc id={file.id} name={file.name} key={file.id} showName />
          ))}
        </div>
      </div>
      <Dialog open={activeModal} onClose={closeModal}>
        <UpdateRiskLevel userAddress={username} callback={updateRiskCallback} />
      </Dialog>
    </Fragment>
  );
});

UserRiskProfileEvaluation.propTypes = {
  user: PropTypes.object,
  getDetails: PropTypes.func
};

export default UserRiskProfileEvaluation;
