import axios from 'axios';
import api, { API_ROOT } from '../config/api';
import {
  AUTH_USER,
  AUTH_ERROR,
  AUTH_CLEAR_ROLES,
  AUTH_SET_ROLES
} from './types';
import { handleError } from '../utils/error.utils';

export const login = (username, password, callback) => async dispatch => {
  const config = {
    data: `username=${username}&password=${password}&grant_type=password`,
    headers: {
      Authorization: 'Basic Y2FzaDM2LWNsaWVudDpjYXNoMzYtc2VjcmV0',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  try {
    const response = await axios.post(
      `${API_ROOT}/auth/oauth/token`,
      config.data,
      { headers: config.headers }
    );

    const token = response.data;

    sessionStorage.setItem('access_token', token.access_token);
    sessionStorage.setItem('refresh_token', token.refresh_token);
    let expiresAt = new Date().getTime() + token.expires_in * 1000;
    sessionStorage.setItem('expires_at', expiresAt);

    dispatch({
      type: AUTH_USER,
      payload: true
    });
    callback();
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error.response.data.error_description
    });
    return handleError(error);
  }
};

export const logout = () => dispatch => {
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('expires_at');
  dispatch({
    type: AUTH_USER,
    payload: false
  });
};

export const getCurrentUser = () => async dispatch => {
  try {
    const response = await api.get('/compliance/current-user');
    dispatch({
      type: AUTH_SET_ROLES,
      payload: response.data.roles
    });
  } catch (error) {
    return handleError(error);
  }
};

export const mint = () => async dispatch => {
  try {
    const response = await api.get('/exchange/admin/trigger/mint');
    console.print("respone"+response)
  } catch (error) {
    return handleError(error);
  }
};

export const outgoing = () => async dispatch => {
  try {
    const response = await api.get('/exchange/admin/trigger/outgoing');
    console.print("respone"+response)
  } catch (error) {
    return handleError(error);
  }
};

export const incoming = () => async dispatch => {
  try {
    const response = await api.get('/exchange/admin/trigger/incoming');
    console.print("respone"+response)
  } catch (error) {
    return handleError(error);
  }
};

export const clearUserRoles = () => dispatch => {
  dispatch({
    type: AUTH_CLEAR_ROLES
  });
};
